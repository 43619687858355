import { getUserIdFromContext } from '@/services/auth'

export default ({ app }) => {
  try {
    const id = getUserIdFromContext(app.context)

    app.$logRocket.getSessionURL(sessionURL => {
      if (app.$sentry) {
        app.$sentry.configureScope(scope => {
          scope.setExtra('sessionURL', sessionURL)
        })
      }
    })

    if (id) {
      app.$initLogRocket()

      app.$logRocket.identify(id)
    }
  } catch (error) {
    if (app.$sentry) {
      app.$sentry.captureException(error)
    } else {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }
}
