/* istanbul ignore file */
import link from '@/apollo/links'
import { getTokenForOperationFromContext, TOKEN_TYPE } from '@/services/auth'

const options = {
  resolvers: {
    Mutation: {
      setError: (root, { value }, { cache } = {}) => {
        const data = {
          error: value,
        }

        cache.writeData({ data })
      },
    },
  },
  onCacheInit: cache => {
    const data = {
      error: null,
    }
    cache.writeData({ data })
  },
}

export default ctx => {
  return {
    ...options,
    link: link(ctx),
    defaultHttpLink: false,
    authenticationType: TOKEN_TYPE,
    tokenName: 'apollo-token',
    getAuth: () => {
      const token = getTokenForOperationFromContext(ctx)

      return `${TOKEN_TYPE} ${token}`
    },
    wsEndpoint: null,

    // ensure not force cache-first
    ssr: false,
    apollo: {
      ssrMode: false,
      ssrForceFetchDelay: 0,

      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'network-only',
        },
        query: {
          fetchPolicy: 'network-only',
        },
      },
    },
  }
}
