import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _67093fae = () => interopDefault(import('../src/pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _0f9794c1 = () => interopDefault(import('../src/pages/agenda/index.vue' /* webpackChunkName: "pages/agenda/index" */))

const _6a144e2c = () => interopDefault(import('../src/pages/community/index.vue' /* webpackChunkName: "pages/community/index" */))
const _318b8e56 = () => interopDefault(import('../src/pages/customers/index.vue' /* webpackChunkName: "pages/customers/index" */))
const _7d5f7e92 = () => interopDefault(import('../src/pages/impersonate.vue' /* webpackChunkName: "pages/impersonate" */))
const _3ecaa3b2 = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _7fcc1f3c = () => interopDefault(import('../src/pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _57a7a96e = () => interopDefault(import('../src/pages/message-docto/index.vue' /* webpackChunkName: "pages/message-docto/index" */))
const _a6ae69e0 = () => interopDefault(import('../src/pages/onboarding/index.vue' /* webpackChunkName: "pages/onboarding/index" */))
const _a4075bec = () => interopDefault(import('../src/pages/pro-profile/index.vue' /* webpackChunkName: "pages/pro-profile/index" */))
const _c4585ca4 = () => interopDefault(import('../src/pages/registration/index.vue' /* webpackChunkName: "pages/registration/index" */))
const _3ce57c02 = () => interopDefault(import('../src/pages/account/administrative.vue' /* webpackChunkName: "pages/account/administrative" */))
const _83ce0852 = () => interopDefault(import('../src/pages/account/billing.vue' /* webpackChunkName: "pages/account/billing" */))
const _08ea96e6 = () => interopDefault(import('../src/pages/account/contract.vue' /* webpackChunkName: "pages/account/contract" */))
const _6fa05307 = () => interopDefault(import('../src/pages/agenda/settings/index.vue' /* webpackChunkName: "pages/agenda/settings/index" */))
const _7f3c0a4a = () => interopDefault(import('../src/pages/community/button.vue' /* webpackChunkName: "pages/community/button" */))
const _0e5c65d1 = () => interopDefault(import('../src/pages/community/google.vue' /* webpackChunkName: "pages/community/google" */))
const _0e756624 = () => interopDefault(import('../src/pages/community/kit.vue' /* webpackChunkName: "pages/community/kit" */))
const _0ddd01e0 = () => interopDefault(import('../src/pages/community/partner.vue' /* webpackChunkName: "pages/community/partner" */))
const _847b80f4 = () => interopDefault(import('../src/pages/community/phone.vue' /* webpackChunkName: "pages/community/phone" */))
const _d6dab460 = () => interopDefault(import('../src/pages/community/signature.vue' /* webpackChunkName: "pages/community/signature" */))
const _0aad35f6 = () => interopDefault(import('../src/pages/community/social.vue' /* webpackChunkName: "pages/community/social" */))
const _3eaf9ee0 = () => interopDefault(import('../src/pages/onboarding/administrative.vue' /* webpackChunkName: "pages/onboarding/administrative" */))
const _9f413414 = () => interopDefault(import('../src/pages/onboarding/appointment-formula.vue' /* webpackChunkName: "pages/onboarding/appointment-formula" */))
const _032b29c5 = () => interopDefault(import('../src/pages/onboarding/finish.vue' /* webpackChunkName: "pages/onboarding/finish" */))
const _05414e94 = () => interopDefault(import('../src/pages/onboarding/formations.vue' /* webpackChunkName: "pages/onboarding/formations" */))
const _f099f568 = () => interopDefault(import('../src/pages/onboarding/presentation.vue' /* webpackChunkName: "pages/onboarding/presentation" */))
const _d11f02d0 = () => interopDefault(import('../src/pages/onboarding/recommendations.vue' /* webpackChunkName: "pages/onboarding/recommendations" */))
const _342924dc = () => interopDefault(import('../src/pages/onboarding/webinaire.vue' /* webpackChunkName: "pages/onboarding/webinaire" */))
const _5c6687ee = () => interopDefault(import('../src/pages/onboarding/work-environment.vue' /* webpackChunkName: "pages/onboarding/work-environment" */))
const _4f2a29d0 = () => interopDefault(import('../src/pages/pro-profile/insight.vue' /* webpackChunkName: "pages/pro-profile/insight" */))
const _a62775d4 = () => interopDefault(import('../src/pages/pro-profile/reviews/index.vue' /* webpackChunkName: "pages/pro-profile/reviews/index" */))
const _dba5788a = () => interopDefault(import('../src/pages/signature/contract.vue' /* webpackChunkName: "pages/signature/contract" */))
const _da7b9b26 = () => interopDefault(import('../src/pages/signature/payment.vue' /* webpackChunkName: "pages/signature/payment" */))
const _037c86a3 = () => interopDefault(import('../src/pages/signature/payment-processing.vue' /* webpackChunkName: "pages/signature/payment-processing" */))
const _425b9ca6 = () => interopDefault(import('../src/pages/agenda/settings/appointment-formula.vue' /* webpackChunkName: "pages/agenda/settings/appointment-formula" */))
const _2aeccfbc = () => interopDefault(import('../src/pages/agenda/settings/google-calendars.vue' /* webpackChunkName: "pages/agenda/settings/google-calendars" */))
const _791163b2 = () => interopDefault(import('../src/pages/agenda/settings/work-environment.vue' /* webpackChunkName: "pages/agenda/settings/work-environment" */))
const _1fd494fe = () => interopDefault(import('../src/pages/pro-profile/reviews/_id.vue' /* webpackChunkName: "pages/pro-profile/reviews/_id" */))
const _8b95a384 = () => interopDefault(import('../src/pages/customers/_id.vue' /* webpackChunkName: "pages/customers/_id" */))
const _0dbf37a1 = () => interopDefault(import('../src/pages/signature/_id.vue' /* webpackChunkName: "pages/signature/_id" */))
const _16f30b10 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _67093fae,
    name: "account"
  }, {
    path: "/agenda",
    component: _0f9794c1,
    name: "agenda",
    children: [{
      path: "rdv-clients/:businessEventId",
      name: "agenda-rdv-clients-id"
    }, {
      path: "week/:year/:month/:day",
      name: "agenda-week-events"
    }]
  }, {
    path: "/community",
    component: _6a144e2c,
    name: "community"
  }, {
    path: "/customers",
    component: _318b8e56,
    name: "customers"
  }, {
    path: "/impersonate",
    component: _7d5f7e92,
    name: "impersonate"
  }, {
    path: "/login",
    component: _3ecaa3b2,
    name: "login"
  }, {
    path: "/logout",
    component: _7fcc1f3c,
    name: "logout"
  }, {
    path: "/message-docto",
    component: _57a7a96e,
    name: "message-docto"
  }, {
    path: "/onboarding",
    component: _a6ae69e0,
    name: "onboarding"
  }, {
    path: "/pro-profile",
    component: _a4075bec,
    name: "pro-profile"
  }, {
    path: "/registration",
    component: _c4585ca4,
    name: "registration"
  }, {
    path: "/account/administrative",
    component: _3ce57c02,
    name: "account-administrative"
  }, {
    path: "/account/billing",
    component: _83ce0852,
    name: "account-billing"
  }, {
    path: "/account/contract",
    component: _08ea96e6,
    name: "account-contract"
  }, {
    path: "/agenda/settings",
    component: _6fa05307,
    name: "agenda-settings"
  }, {
    path: "/community/button",
    component: _7f3c0a4a,
    name: "community-button"
  }, {
    path: "/community/google",
    component: _0e5c65d1,
    name: "community-google"
  }, {
    path: "/community/kit",
    component: _0e756624,
    name: "community-kit"
  }, {
    path: "/community/partner",
    component: _0ddd01e0,
    name: "community-partner"
  }, {
    path: "/community/phone",
    component: _847b80f4,
    name: "community-phone"
  }, {
    path: "/community/signature",
    component: _d6dab460,
    name: "community-signature"
  }, {
    path: "/community/social",
    component: _0aad35f6,
    name: "community-social"
  }, {
    path: "/onboarding/administrative",
    component: _3eaf9ee0,
    name: "onboarding-administrative"
  }, {
    path: "/onboarding/appointment-formula",
    component: _9f413414,
    name: "onboarding-appointment-formula"
  }, {
    path: "/onboarding/finish",
    component: _032b29c5,
    name: "onboarding-finish"
  }, {
    path: "/onboarding/formations",
    component: _05414e94,
    name: "onboarding-formations"
  }, {
    path: "/onboarding/presentation",
    component: _f099f568,
    name: "onboarding-presentation"
  }, {
    path: "/onboarding/recommendations",
    component: _d11f02d0,
    name: "onboarding-recommendations"
  }, {
    path: "/onboarding/webinaire",
    component: _342924dc,
    name: "onboarding-webinaire"
  }, {
    path: "/onboarding/work-environment",
    component: _5c6687ee,
    name: "onboarding-work-environment"
  }, {
    path: "/pro-profile/insight",
    component: _4f2a29d0,
    name: "pro-profile-insight"
  }, {
    path: "/pro-profile/reviews",
    component: _a62775d4,
    name: "pro-profile-reviews"
  }, {
    path: "/signature/contract",
    component: _dba5788a,
    name: "signature-contract"
  }, {
    path: "/signature/payment",
    component: _da7b9b26,
    name: "signature-payment"
  }, {
    path: "/signature/payment-processing",
    component: _037c86a3,
    name: "signature-payment-processing"
  }, {
    path: "/agenda/settings/appointment-formula",
    component: _425b9ca6,
    name: "agenda-settings-appointment-formula"
  }, {
    path: "/agenda/settings/google-calendars",
    component: _2aeccfbc,
    name: "agenda-settings-google-calendars"
  }, {
    path: "/agenda/settings/work-environment",
    component: _791163b2,
    name: "agenda-settings-work-environment"
  }, {
    path: "/pro-profile/reviews/:id",
    component: _1fd494fe,
    name: "pro-profile-reviews-id"
  }, {
    path: "/customers/:id",
    component: _8b95a384,
    name: "customers-id"
  }, {
    path: "/signature/:id?",
    component: _0dbf37a1,
    name: "signature-id"
  }, {
    path: "/",
    component: _16f30b10,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
