<template>
  <div class="tagging">
    <ValidationObserver
      ref="observerTagging"
      v-slot="{ invalid }"
      class="form__tagging"
      tag="form"
      autocomplete="off"
      @submit.prevent="submit()"
    >
      <ValidationProvider
        v-slot="{ valid, errors }"
        :vid="$attrs.id"
        :name="$attrs.id"
        :rules="rules"
        slim
      >
        <BInputGroup>
          <BFormInput
            v-model="typingTag"
            class="tagging__input"
            :placeholder="$t(placeholder)"
            :state="errors[0] ? false : valid ? null : null"
            trim
            @keypress.enter.tab.stop.self="submit"
          ></BFormInput>
          <BInputGroupAppend>
            <BButton
              variant="outline-primary"
              class="tagging__submit"
              type="submit"
              :disabled="invalid"
              >{{ $t('button.add') }}</BButton
            >
          </BInputGroupAppend>
        </BInputGroup>

        <div v-if="errors" class="invalid-feedback d-block">
          {{ errors[0] }}
        </div>
      </ValidationProvider>
    </ValidationObserver>

    <div class="tagging__tags">
      <Tag
        v-for="(tag, index) of savedTags"
        :key="index"
        removable
        grey
        @remove="remove(tag)"
        >{{ tag.name }}</Tag
      >
    </div>
  </div>
</template>
<script>
import {
  BInputGroup,
  BFormInput,
  BButton,
  BInputGroupAppend,
} from 'bootstrap-vue'
import Tag from '@/components/tag'
import { Form } from '@/mixins/form'

export default {
  components: { BInputGroup, BFormInput, BButton, BInputGroupAppend, Tag },
  mixins: [Form],
  props: {
    placeholder: {
      type: String,
      default: 'Tagging.placeholder.typeKeyword',
    },
    /**
     * v-model
     */
    value: {
      type: null,
      default: null,
    },
    rules: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    typingTag: null,
    savedTags: [],
  }),
  watch: {
    /**
     * Update list when v-model value change
     */
    value: {
      immediate: true,
      handler() {
        this.savedTags = [...(this.value || [])]
      },
    },
  },
  methods: {
    async submit() {
      const isValid = await this.$refs.observerTagging.validate()
      if (!isValid) {
        return false
      }
      this.add()
    },
    add() {
      // prevent duplicate
      if (
        this.typingTag &&
        !this.savedTags.find(t => t.name === this.typingTag)
      ) {
        const newValue = [...this.savedTags]
        const newTag = { id: null, name: this.typingTag }
        newValue.push(newTag)
        this.savedTags = [...newValue]

        // this.$emit('input', newValue)
        this.$emit('added', newTag)
        this.$emit('input', this.savedTags)
      }
      this.typingTag = null
    },
    remove(tag) {
      const index = this.savedTags.findIndex(
        t => t.id === tag.id && t.name === tag.name
      )
      const newValue = this.savedTags
        .slice(0, index)
        .concat(this.savedTags.slice(index + 1))

      this.savedTags = [...newValue]
      // this.$emit('input', newValue)
      this.$emit('removed', tag)
      this.$emit('input', this.savedTags)
    },
  },
}
</script>
<style lang="scss" scoped>
.tagging {
  &__tags {
    margin-top: 20px;
  }
  &__input {
    border-right-width: 0;

    @include media-breakpoint-down(sm) {
      border-right-width: 1px;
    }
  }
  &__submit {
    line-height: 0.5;

    &:disabled {
      color: $gray-light;
      border-color: $gray-light;
    }

    @media screen and (max-width: 1042px) {
      min-width: fit-content;
    }

    @include media-breakpoint-down(sm) {
      min-width: fit-content;
      margin-bottom: 0;
    }
  }
}
</style>
