import {
  validateOnboarding,
  queryMyBusinessSignupStates,
} from '@/services/workflow/validate-step-access'

// eslint-disable-next-line consistent-return
export default async function(context) {
  const { redirect, error, app, route } = context
  const BusinessSignupStates = await queryMyBusinessSignupStates(
    app.apolloProvider.defaultClient
  )

  return validateOnboarding({
    redirect,
    error,
    BusinessSignupStates,
    routeName: route.name,
  })
}
