<template>
  <div class="onboarding-menu">
    <OnboardingStatus />
    <div
      v-for="item in menuItems"
      :key="item.name"
      class="onboarding-menu__items"
    >
      <h2 class="onboarding-menu__items__title">
        {{ $t(item.name) }}
      </h2>

      <MenuItem
        v-for="button in item.buttons"
        :key="$t(button.label)"
        v-bind="button"
      />
    </div>
  </div>
</template>

<script>
import MenuItem from '@/components/onboarding-menu-item'
import OnboardingStatus from '@/components/onboarding-status'
import { showMyOnboardingProgress } from '@/graphql/user.gql'

const MENU_ITEMS = [
  {
    name: 'OnboardingMenu.groupTitle.myInformations',
    buttons: [
      {
        route: '',
        label: 'OnboardingMenu.button.loginAndBilling',
        disabled: true,
        completedStateName: ['ONBORDING_STARTED', 'PAYMENT_METHOD_VALIDATED'],
      },
      {
        route: 'onboarding-administrative',
        label: 'OnboardingMenu.button.administrativeInformations',
        completedStateName: 'ADMINISTRATION_COMPLETED',
      },
      {
        route: 'onboarding-formations',
        label: 'OnboardingMenu.button.myCertification',
        completedStateName: 'FORMATIONS',
      },
    ],
  },
  /* TO DO : APPOINTMENT PHOTOGRAPHER - WRITTER
  {
    name: 'OnboardingMenu.groupTitle.myAppointment',
    buttons: [
      {
        route: 'onboarding',
        label: 'OnboardingMenu.button.writterAppointment',
        completedStateName: 'WRITTER_APPOINTMENT',
      },
      {
        route: 'onboarding',
        label: 'OnboardingMenu.button.photoAppointment',
        completedStateName: 'PHOTOGRAPHER_APPOINTMENT',
      },
    ],
  }, */

  {
    name: 'OnboardingMenu.groupTitle.myMedoucinePage',
    buttons: [
      {
        route: 'onboarding-recommendations',
        label: 'OnboardingMenu.button.recommendations',
        completedStateName: 'RECOMMENDED',
      },
      {
        route: 'onboarding-presentation',
        label: 'OnboardingMenu.button.presentation',
        completedStateName: 'PUBLIC_PROFILE_COMPLETED',
      },
    ],
  },
  {
    name: 'OnboardingMenu.groupTitle.myCalendarSettings',
    buttons: [
      {
        route: 'onboarding-work-environment',
        label: 'OnboardingMenu.button.workEnvironments',
        completedStateName: 'WORK_ENVIRONMENT_PROVIDED',
      },
      {
        route: 'onboarding-appointment-formula',
        label: 'OnboardingMenu.button.appointmentFormulas',
        completedStateName: 'APPOINTMENT_FORMULA_PROVIDED',
      },
    ],
  },
  {
    name: 'OnboardingMenu.groupTitle.webinaireInscription',
    buttons: [
      {
        route: 'onboarding-webinaire',
        label: 'OnboardingMenu.button.webinaireInscription',
        completedStateName: 'WEBINAIRE_APPOINTMENT',
      },
    ],
  },
]
export default {
  components: {
    MenuItem,
    OnboardingStatus,
  },
  data: () => ({
    states: [],
  }),
  apollo: {
    states: {
      query: showMyOnboardingProgress,
      update: data =>
        data.me.Business.SignUp.CurrentStates.nodes.map(s => s.state),
      fetchPolicy: 'network-only',
    },
  },
  computed: {
    menuItems() {
      return MENU_ITEMS.map(section => {
        section.buttons.map(b => {
          let state = 'default'
          let completed = false

          if (b.disabled) {
            state = 'disabled'
          }

          completed = this.states.includes(b.completedStateName)

          if (typeof b.completedStateName === 'object') {
            for (const state of b.completedStateName) {
              completed = this.states.includes(state)
              if (completed) {
                break
              }
            }
          }

          b.state = state
          b.completed = completed

          return b
        })

        return section
      })
    },
  },
  watch: {
    $route(to, from) {
      this.$apollo.queries.states && this.$apollo.queries.states.refresh()
    },
  },
}
</script>
