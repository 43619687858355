<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ invalid }"
    tag="form"
    class="form"
    @submit.prevent="submit()"
  >
    <FormErrors :errors="unknownErrors" />

    <Field
      v-model="form.email"
      :label="$t('label.emailAddress')"
      rules="required|email"
      :placeholder="$t('placeholder.emailAddress:yours')"
      name="email"
    />

    <Field
      v-model="form.password"
      :label="$t('label.password')"
      rules="required"
      name="password"
      type="password"
    />
    <a class="float-right" :href="forgottenPasswordUrl">Mot de passe oublié</a>

    <div class="form__submit">
      <BaseButton
        text="button.login"
        :loading="formSaving"
        type="submit"
        :disabled="invalid"
      />
    </div>
  </ValidationObserver>
</template>

<script>
import { login } from '@/graphql/auth.gql'
import { Form } from '@/mixins/form'
import BaseButton from '@/components/base-button'

export default {
  dataLayer: {
    action: 'login',
    formName: 'login',
  },
  components: {
    BaseButton,
  },
  mixins: [Form],
  data: () => ({
    form: {
      email: null,
      password: null,
    },
    authError: null,
  }),
  computed: {
    forgottenPasswordUrl() {
      return process.env.APP_CLIENT_BASE_URL + '/password/reset'
    },
  },
  methods: {
    onSaveOverride() {
      const mutation = login
      const variables = {
        input: {
          CreateToken: {
            username: this.form.email,
            password: this.form.password,
          },
        },
      }

      return this.$apollo
        .mutate({
          mutation,
          variables,
          fetchPolicy: 'no-cache',
        })
        .then(({ data: { createToken: { Token: { token } } } }) => {
          this.setGTMUser(this.form.email)
          this.authenticated(token)
        })
    },
    async authenticated(token) {
      await this.$apolloHelpers.onLogin(token)
      this.$emit('authenticated')
    },
    setGTMUser(emailAddress) {
      if (!window.dataLayer) {
        window.dataLayer = []
      }
      window.dataLayer.push({
        event: 'login',
        user: {
          emailAddress,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.form {
  &__submit {
    margin-top: rem(60px);
    text-align: center;
  }
}
</style>
