import LogRocket from 'logrocket'
import { userIsLoggedFromContext } from '@/services/auth'

export default function(context, inject) {
  const LOGROCKET_ID = process.env.LOGROCKET_ID
  let initiated = false

  const initLogRocket = () => {
    if (!LOGROCKET_ID || initiated) {
      return
    }

    const options = {
      shouldParseXHRBlob: true,
    }

    if (process.env.APP_VERSION) {
      options.release = process.env.APP_VERSION
    }

    initiated = true
    LogRocket.init(LOGROCKET_ID, options)

    window.LogRocket = LogRocket
  }

  try {
    if (userIsLoggedFromContext(context)) {
      initLogRocket()
    }
  } catch (_) {}

  inject('logRocket', LogRocket)
  inject('initLogRocket', initLogRocket)
}
