<template>
  <div class="modal__buttons buttons">
    <button
      v-show="!loading && showCancelBtn"
      type="button"
      :class="submitBtnClass"
      class="btn btn-outline-primary btn-cancel"
      @click="onCancel"
      >{{ $t('button.cancel') }}</button
    >
    <button
      :class="
        `a-btn -corail btn btn-${variant} btn-secondary btn-submit ${submitBtnClass}`
      "
      :type="submitable ? 'submit' : 'button'"
      :disabled="disabled"
      @click="onSubmit"
    >
      <BSpinner v-if="loading" small type="grow"></BSpinner>
      <span v-show="!loading">{{ $t(submitBtnText) }}</span>
      <span v-show="loading">{{ $t('button.loading') }}</span>
    </button>
    <slot name="right"></slot>
  </div>
</template>
<script>
import { BSpinner } from 'bootstrap-vue'

export default {
  components: { BSpinner },
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    submitable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onCancel: {
      type: Function,
      default: () => {},
    },
    showCancelBtn: {
      type: Boolean,
      default: true,
    },
    onSubmit: {
      type: Function,
      default: () => {},
    },
    submitBtnClass: {
      type: null,
      default: '',
    },
    cancelBtnClass: {
      type: null,
      default: '',
    },
    submitBtnText: {
      type: String,
      default: 'button.save',
    },
  },
}
</script>
