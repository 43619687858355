<template>
  <div>
    <a v-if="isHref" :href="route" class="m-submenuApp__item">
      <component :is="icon" v-if="icon" />
      <span class="menu-item__label">{{ $t(label) }}</span>
    </a>

    <nuxt-link v-else :to="route" class="m-submenuApp__item">
      <component :is="icon" v-if="icon" />
      <span class="menu-item__label">{{ $t(label) }}</span>
    </nuxt-link>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    route: {
      type: String,
      required: true,
    },
    isHref: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
