<template>
  <div>
    <!-- Menu App Mobile -->
    <MenuApp v-if="isFromAppPro()" />
    <Navbar v-else />
    <div class="container-fluid">
      <nuxt />
    </div>
    <GlobalErrorReporter />
  </div>
</template>

<script>
import MenuApp from '../components/app-pro/menu-app.vue'
import Navbar from '@/components/navbar'
import GlobalErrorReporter from '@/components/global-error-reporter'
import { appPro } from '@/mixins/app-pro'

export default {
  components: {
    MenuApp,
    Navbar,
    GlobalErrorReporter,
  },
  mixins: [appPro],
}
</script>
