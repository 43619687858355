<template>
  <div class="sidebar">
    <!-- Menu App Mobile -->
    <MenuApp v-if="isFromAppPro()" />
    <Navbar v-else />
    <div class="container-fluid">
      <div class="row">
        <Menu
          class="col-md-4 col-xl-3 sidebar__menu d-none d-md-block"
          :backlink="menu.backlink"
          :items="menu.items"
        />
        <div class="col-12 col-xl-7 col-md-8 sidebar__main">
          <nuxt-link
            v-if="!isFromAppPro()"
            :to="{ name: 'agenda-settings' }"
            class="d-block d-md-none mb-5 backlink"
          >
            <BackIcon class="back-icon" />
            {{ $t('AgendaSettings.backlink:settings') }}
          </nuxt-link>

          <nuxt />
        </div>
      </div>
    </div>
    <GlobalErrorReporter />
  </div>
</template>

<script>
import MenuApp from '../components/app-pro/menu-app.vue'
import Navbar from '@/components/navbar'
import Menu from '@/components/sidebar-menu'
import BackIcon from '@/assets/icons/arrow-left.svg'
import GlobalErrorReporter from '@/components/global-error-reporter'
import { appPro } from '@/mixins/app-pro'

export default {
  components: {
    MenuApp,
    Navbar,
    Menu,
    BackIcon,
    GlobalErrorReporter,
  },
  mixins: [appPro],
  data() {
    return {
      menu: {
        backlink: {
          name: this.$t('AgendaSettings.backlink'),
          route: 'agenda',
        },
        items: [
          {
            name: this.$t('AgendaSettings.menu.workEnvironmentLabel'),
            route: 'agenda-settings-work-environment',
          },
          {
            name: this.$t('AgendaSettings.menu.motiveLabel'),
            route: 'agenda-settings-appointment-formula',
          },
          {
            name: this.$t('AgendaSettings.menu.agendaSyncLabel'),
            route: 'agenda-settings-google-calendars',
          },
          {
            name: this.$t('AgendaSettings.menu.payementLabel'),
            internalLink: '/agenda/settings/payment',
          },
          {
            name: this.$t('AgendaSettings.menu.visio'),
            internalLink: '/agenda/settings/visio',
          },
        ],
      },
    }
  },
}
</script>
<style lang="scss" scoped>
.backlink {
  font-size: $font-size-sm;
}
.sidebar {
  &__menu {
    min-height: 100vh;
    margin-top: rem(-20px);
  }

  &__main {
    padding-top: rem(26px);
    padding-bottom: spacing(5);

    @include media-breakpoint-up(md) {
      padding-left: rem(70px);
    }
  }
}
</style>
