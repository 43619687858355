<template>
  <div class="all-day-checkbox">
    <Field
      v-model="isChecked"
      :label="$t('DowntimeForm.allDay')"
      name="allDay"
      component="BFormCheckbox"
    />
  </div>
</template>
<script>
import { getHourWithOffset } from '@/services/util'

export default {
  components: {},
  props: {
    value: {
      default: false,
      type: Boolean,
    },
    startDate: { required: true, type: Date },
    endDate: { required: true, type: Date },
  },
  data() {
    return {
      isChecked: this.value,
    }
  },
  watch: {
    isChecked: {
      handler(val) {
        this.emit()
      },
    },
    startDate: {
      handler(val) {
        this.emit()
      },
    },
    endDate: {
      handler(val) {
        this.emit()
      },
    },
  },
  methods: {
    emit() {
      this.$emit('input', this.isChecked)

      if (this.isChecked === true) {
        this.$emit('all-day:checked', {
          startTime: getHourWithOffset(this.startDate, '00', '00'),
          endTime: getHourWithOffset(this.endDate, '23', '55'),
        })
      }
    },
  },
}
</script>
