import {
  validateDashboard,
  queryMyBusinessSignupStates,
} from '@/services/workflow/validate-step-access'
import { userIsAnonymousFromContext } from '@/services/auth'

// eslint-disable-next-line consistent-return
export default async function(context) {
  const { redirect, app, route } = context
  const routeName = route.name || route.path

  if (
    !routeName.includes('onboarding') &&
    !routeName.includes('signature') &&
    !routeName.includes('login') &&
    !routeName.includes('impersonate') &&
    !routeName.includes('registration') &&
    !routeName.includes('logout')
  ) {
    if (userIsAnonymousFromContext(context)) {
      return redirect({ name: 'login' })
    }

    const BusinessSignupStates = await queryMyBusinessSignupStates(
      app.apolloProvider.defaultClient
    )
    return validateDashboard({ redirect, BusinessSignupStates })
  }
}
