<template>
  <div class="work-environment-select">
    <Multiselect
      v-model="selectValue"
      :options="options"
      :show-labels="false"
      :show-pointer="false"
      :searchable="false"
      :preserve-search="true"
      :placeholder="placeholder || $t('placeholder.workEnvironment:select')"
      :disabled="disabled"
    >
      <template slot="option" slot-scope="option">
        <component
          :is="$options.icons[option.option.__typename]"
          class="work-environment-select__icon"
        />
        {{ option.option.text }}
      </template>
      <template slot="singleLabel" slot-scope="option">
        <component
          :is="$options.icons[option.option.__typename]"
          class="work-environment-select__icon"
        />
        {{ option.option.text }}
      </template>
      <template #noOptions>{{ $t('noOptions') }}</template>
      <template #noResult>{{ $t('noResult') }}</template>
    </Multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import TravelZoneIcon from '@/assets/icons/home.svg'
import AppointmentPlaceIcon from '@/assets/icons/pin_2.svg'
import RemoteWorkPreferenceIcon from '@/assets/icons/webcam-small.svg'

const icons = {
  AppointmentPlace: AppointmentPlaceIcon,
  TravelZone: TravelZoneIcon,
  RemoteWorkPreference: RemoteWorkPreferenceIcon,
}

export default {
  icons,
  components: {
    Multiselect,
  },
  props: {
    targetedProps: { required: false },
    workEnvironmentOptions: { required: true },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      innerValue: this.value,
      selectValue: null,
    }
  },
  computed: {
    options() {
      return this.workEnvironmentOptions.map(opt => ({
        ...opt,
        value: opt.id,
        text: opt.name,
      }))
    },
  },
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit('input', newVal)
      this.$emit('change', newVal) // to keep the actual @change Handler
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal
    },
    // Handles multiselect model changes.
    selectValue(newVal) {
      if (newVal && this.targetedProps) {
        this.innerValue = newVal[this.targetedProps]
        if (!this.innerValue) {
          this.innerValue = newVal
        }
      } else {
        this.innerValue = newVal
      }
    },
    options: {
      immediate: true,
      handler(newOptions) {
        if (this.value && this.targetedProps) {
          this.selectValue = newOptions.find(
            o => o[this.targetedProps] === this.value
          )
          if (!this.selectValue) {
            this.selectValue = this.value
          }
        } else {
          this.selectValue = this.value
        }
      },
    },
  },
}
</script>

<style lang="scss">
.work-environment-select {
  &__icon {
    width: auto;
    height: 18px;
    margin-right: 6px;
  }

  .multiselect {
    &__tags {
      padding: 13px;
    }
    &__placeholder {
      padding: 0;
      margin: 0;
      color: #35495e;
    }
    &__single {
      margin: 4px 0;
      font-size: 18px;
    }
    &__select {
      z-index: 1;
    }
  }
}
</style>
