const WORKFLOW_STEP_ORDER = [
  'signature-id',
  'signature-contract',
  'signature-payment',
]

export function getCurrentStep(
  rules,
  { networkError, graphQLErrors, membershipOffer, BusinessSignupStates }
) {
  const context = {
    networkError,
    graphQLErrors,
    membershipOffer,
    BusinessSignupStates,
  }
  const validSteps = WORKFLOW_STEP_ORDER.filter(step => {
    const validations = rules[step]

    if (!validations) {
      return false
    }

    // eslint-disable-next-line no-unused-vars
    for (const [name, rule] of Object.entries(validations)) {
      try {
        if (!rule(context)) {
          return false
        }
      } catch (error) {
        return false
      }
    }

    return true
  })

  if (validSteps) {
    return validSteps[validSteps.length - 1]
  }
}

export function getRedirectPath(
  rules,
  { networkError, graphQLErrors, membershipOffer, BusinessSignupStates }
) {
  const context = {
    networkError,
    graphQLErrors,
    membershipOffer,
    BusinessSignupStates,
  }
  return getCurrentStep(rules, context)
}
