import { getUserIdFromContext } from '@/services/auth'

export default ({ app }) => {
  try {
    const id = getUserIdFromContext(app.context)

    if (id) {
      app.$sentry && app.$sentry.setUser({ id })
    }
  } catch (error) {
    if (app.$sentry) {
      app.$sentry.captureException(error)
    } else {
      console.error(error)
    }
  }
}
