import {
  signatureRules,
  signatureErrorHandlers,
} from './signature-step-validations'

import {
  getMembershipOfferState,
  getMyBusinessSignupStates,
} from '@/graphql/user.gql'

const pageValidationRules = {
  ...signatureRules,
}

const errorHandlers = {
  ...signatureErrorHandlers,
}

export default validate
export async function validate({
  params,
  apolloClient,
  routeName,
  error,
  redirect,
  getRedirectPath,
}) {
  const rules = pageValidationRules[routeName]
  if (!rules) {
    throw new Error('Undefined validation page :' + routeName)
  }

  let membershipOffer = {}
  let BusinessSignupStates = {}
  let queryError = {}

  try {
    if (routeName === 'signature-id') {
      membershipOffer = await queryMembershipOfferState(apolloClient, params.id)
    } else {
      BusinessSignupStates = await queryMyBusinessSignupStates(apolloClient)
    }
  } catch (error_) {
    queryError = error_
  }
  const { networkError, graphQLErrors } = queryError

  const context = {
    networkError,
    graphQLErrors,
    membershipOffer,
    BusinessSignupStates,
    error,
    redirect,
    getRedirectPath,
    rules: pageValidationRules,
  }

  for (const [name, rule] of Object.entries(rules)) {
    if (rule(context)) {
      return errorHandlers[routeName][name](context)
    }
  }

  return true
}

export const validateOnboarding = ({
  BusinessSignupStates,
  routeName,
  redirect,
}) => {
  const onboardingHasStarted = BusinessSignupStates.find(s =>
    ['ONBORDING_STARTED', 'PAYMENT_METHOD_VALIDATED'].includes(s.state)
  )
  const onboardingIsDone = BusinessSignupStates.find(s => s.state === 'DONE')
  const validationIsInProgress = BusinessSignupStates.find(
    s => s.state === 'VALIDATION_IN_PROGRESS'
  )
  const isOnboardingPage = routeName.includes('onboarding')

  if (onboardingIsDone) {
    redirect({ name: 'index' })
  } else if (validationIsInProgress) {
    if (!isOnboardingPage) {
      redirect({ name: 'onboarding' })
    }
  } else if (onboardingHasStarted) {
    if (isOnboardingPage === false) {
      redirect({ name: 'onboarding' })
    }
  } else {
    redirect({ name: 'signature-contract' })
  }
}
export const validateDashboard = ({ BusinessSignupStates, redirect }) => {
  const onboardingIsDone = BusinessSignupStates.find(s => s.state === 'DONE')

  if (!onboardingIsDone) {
    return redirect({ name: 'onboarding' })
  }
}

async function queryMembershipOfferState(apolloClient, id) {
  const variables = { input: { MembershipOffer: { id } } }
  const query = getMembershipOfferState

  const {
    data: {
      getMembershipOffer: { MembershipOffer },
    },
  } = await apolloClient.query({
    query,
    variables,
    fetchPolicy: 'network-only',
  })

  return MembershipOffer
}

export async function queryMyBusinessSignupStates(apolloClient) {
  const query = getMyBusinessSignupStates

  const {
    data: {
      me: {
        Business: {
          SignUp: {
            CurrentStates: { nodes },
          },
        },
      },
    },
  } = await apolloClient.query({
    query,
    fetchPolicy: 'network-only',
  })

  return nodes
}
