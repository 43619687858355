import { Duration } from 'luxon'
import { format, differenceInMinutes } from 'date-fns'

const ALL_DAY_DURATION = 1435

export const isPeriodAllDay = (startDate, endDate) => {
  return differenceInMinutes(endDate, startDate) >= ALL_DAY_DURATION
}

export const getTimezoneOffset = date => {
  const offset = date.getTimezoneOffset() * -1
  const type = offset >= 0 ? '+' : '-'
  const hourOffset = Math.floor(Math.abs(offset) / 60)
    .toString()
    .padStart(2, '0')
  const minuteOffset = Math.floor(Math.abs(offset) % 60)
    .toString()
    .padStart(2, '0')

  return `${type}${hourOffset}:${minuteOffset}`
}

const addHierarchyPosition = (list, position = 0) => {
  return list.map(item => {
    item.position = position
    if (item.Children && item.Children.nodes) {
      item.Children.nodes = addHierarchyPosition(
        item.Children.nodes,
        position + 1
      )
    }

    return item
  })
}

const flatten = list => {
  return list.reduce((arr, elem) => {
    arr.push(elem)
    let children = []
    if (elem.Children && elem.Children.nodes) {
      children = flatten(elem.Children.nodes)
    }

    return arr.concat([...children])
  }, [])
}

export const formatForHierarchy = list => flatten(addHierarchyPosition(list))

export const formatDuration = duration => {
  const minutes = getMinutesFromDuration(duration)

  return formatMinutes(minutes)
}

export const getMinutesFromDuration = duration => {
  const parts = Duration.fromISO(duration).toObject()

  return (parts.minutes || 0) + (parts.hours || 0) * 60
}

export const formatMinutes = value => {
  if (value < 60) {
    return value + 'min'
  }
  const hour = Math.floor(value / 60)
  const minute = value - hour * 60
  return hour + 'h' + (minute > 0 ? minute : '')
}

export const formatHour = value => {
  return value + 'h'
}

export const formatDay = value => {
  const day = value / 24
  if (day === 1) {
    return day + ' jour'
  }
  return day + ' jours'
}

export const getHourWithOffset = (date, hour, minute) => {
  const copiedDate = new Date(date.valueOf()) // copy the date in param in another date obj
  copiedDate.setHours(+hour, +minute)
  return `${hour}:${minute}:00.000${getTimezoneOffset(copiedDate)}`
}

export const formatRFC3333DateTime = date => {
  function pad(n) {
    return n < 10 ? '0' + n : n
  }

  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    getTimezoneOffset(date)
  )
}

export const getFormattedMinutesFromDuration = duration => {
  return formatMinutes(getMinutesFromDuration(duration))
}

export const generateDurationOptions = (start, max = 180) => {
  const step = 5
  let current = start
  let value

  const options = []

  while (current <= max) {
    value = `PT${current}M`

    if (current === 0) {
      value = 'PT0S'
    }

    options.push({
      text: formatMinutes(current),
      value,
    })
    current += step
  }

  return options
}

export const generateDurationOptionsStepped = (start = 10, max = 540) => {
  const options = []
  let step = 5
  let current = start

  while (current <= max) {
    options.push({
      text: formatMinutes(current),
      value: `PT${current}M`,
    })

    if (current >= 120 && current < 180) {
      step = 15
    } else if (current >= 180) {
      step = 30
    }

    current += step
  }

  return options
}

export const generateLargeDurationOptions = (start, max = 23) => {
  const step = 1
  let current = start

  const options = []

  while (current <= max) {
    const isADay = current % 24 === 0
    if (current < 24 || isADay) {
      options.push({
        value: `PT${current}H`,
        text: isADay ? formatDay(current) : formatHour(current),
      })
    }

    current += step
  }

  return options
}

export const formatDate = (isoDate, dateFormat = 'dd/MM/yyyy') => {
  return format(new Date(isoDate), dateFormat)
}

export const validatePhone = value => {
  return /^0\d{9}$/.test(value)
}

export const validateSiret = value => {
  return /^\d{14}$/.test(value.replace(/\s/g, ''))
}

export const validateAdeli = value => {
  return /^\d{9}$/.test(value.replace(/\s/g, ''))
}

export const validateMobilePhone = value => {
  return /^((06)|(07))\d{8}$/.test(value)
}
