<template>
  <div class="hierarchycal-multiselect">
    <Multiselect
      :multiple="true"
      :show-labels="false"
      :show-pointer="false"
      :close-on-select="false"
      v-bind="{
        options,
        trackBy,
        label,
        placeholder,
        id,
        value,
        name,
      }"
      v-on="$listeners"
    >
      <template #noOptions>{{ $t('noOptions') }}</template>
      <template #noResult>{{ $t('noResult') }}</template>
      <template slot="selection" slot-scope="{ values, search, isOpen }">
        <span v-if="values.length && !isOpen" class="multiselect__single"
          >{{ $t('HierarchycalSelect.selectedOptions', [values.length]) }}
        </span>
        <template slot="tags"></template>
      </template>
      <template slot="option" slot-scope="{ search, option }">
        <span :data-position="option.position">
          <AddIcon width="18" height="18" class="unselected-icon" />
          <SelectedIcon width="18" class="selected-icon" />
          {{ getOptionLabel(option) }}
        </span>
      </template>
      ></Multiselect
    >

    <div v-if="bottomTags" class="tags">
      <SlickList
        v-if="sortable"
        v-model="innerValue"
        :use-drag-handle="true"
        class="tags"
        @sort-end="onSort"
      >
        <SlickItem
          v-for="(option, index) of innerValue"
          :key="index"
          :index="index"
          :item="option"
          class="tags__item"
        >
          <Tag removable @remove="removeElement(option)"
            ><DragIcon v-handle class="tags__handle" />{{ index + 1 }}-
            {{ getOptionLabel(option) }}</Tag
          >
        </SlickItem>
      </SlickList>

      <div v-if="!sortable">
        <Tag
          v-for="(option, index) of value"
          :key="index"
          removable
          class="tags__item"
          @remove="removeElement(option)"
          >{{ getOptionLabel(option) }}</Tag
        >
      </div>
    </div>
  </div>
</template>
<script>
import { Multiselect, multiselectMixin } from 'vue-multiselect'

import { SlickList, SlickItem, HandleDirective } from 'vue-slicksort'
import AddIcon from '@/assets/icons/add.svg'
import SelectedIcon from '@/assets/icons/croix.svg'
import DragIcon from '@/assets/icons/dragndrop.svg'
import Tag from '@/components/tag'

export default {
  components: {
    Multiselect,
    AddIcon,
    SelectedIcon,
    Tag,
    SlickList,
    SlickItem,
    DragIcon,
  },
  directives: { handle: HandleDirective },
  /**
   * MultiselectMixin
   * https://vue-multiselect.js.org/
   *
   * Inherited props:
   *  options @type {Array}
   *  trackBy @type {String}
   *  label @type {String}
   *  placeholder @type {String}
   *  id @type {String|Integer}
   *  disabled @type {Boolean}
   *
   * Inehrited methods:
   *   getOptionLabel()
   *
   * Inherited events:
   *  @input
   *  @select
   *  @remove
   *  @searchChange
   *  @tag
   *  @open
   *  @close
   *
   */
  mixins: [multiselectMixin],
  inheritAttrs: false,
  props: {
    /**
     * Activate bottom tags display
     * Must be used in conjonction with "multiple" prop
     */
    bottomTags: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: null,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      innerValue: this.value,
    }
  },
  watch: {
    value(val) {
      this.innerValue = val
    },
  },
  methods: {
    onSort(event) {
      this.innerValue.splice(
        event.newIndex,
        0,
        this.innerValue.splice(event.oldIndex, 1)[0]
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.hierarchycal-multiselect /deep/ {
  .unselected-icon {
    margin-right: 8px;
  }
  .selected-icon {
    display: none;
    margin-right: 8px;
  }

  .multiselect__option--selected .unselected-icon {
    display: none;
  }

  .multiselect__option--selected .selected-icon {
    display: inline;
  }
  .multiselect__option {
    font-weight: bold;
    color: black;
  }
  .multiselect__option > [data-position='1'] {
    margin-left: 30px;
    font-weight: normal;
  }
  .multiselect__option > [data-position='2'] {
    margin-left: 60px;
    font-weight: normal;
  }
}
.tags {
  margin-top: 20px;
  &__handle {
    margin-right: 10px;
    cursor: grab;
  }
  &__item {
    z-index: 1041;
  }
}
</style>
