import { render, staticRenderFns } from "./tagging.vue?vue&type=template&id=0cb5ca16&scoped=true&"
import script from "./tagging.vue?vue&type=script&lang=js&"
export * from "./tagging.vue?vue&type=script&lang=js&"
import style0 from "./tagging.vue?vue&type=style&index=0&id=0cb5ca16&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cb5ca16",
  null
  
)

export default component.exports