<template>
  <div class="m-submenuApp__practician">
    <div class="a-photo">
<!--      Todo : recuperer l'image avec le bon filtre /w100_h100_fit/-->
      <img :src="picture" width="80"/>
    </div>
    <div>{{ $t(firstName) }}<br/>
      {{ $t(lastName) }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    picture: {
      type: String,
      default: null,
    },
    firstName: {
      type: String,
      default: '',
    },
    lastName: {
      type: String,
      default: '',
    }
  }
}
</script>
