const onboardingStarted = ({ BusinessSignupStates }) => {
  return !!BusinessSignupStates.find(s =>
    ['ONBORDING_STARTED', 'PAYMENT_METHOD_VALIDATED'].includes(s.state)
  )
}
const onboardingStartedHandler = ({ redirect }) => {
  redirect({ name: 'onboarding' })
}

export const signatureRules = {
  'signature-id': {
    unknownOffer: ({ graphQLErrors }) => {
      return (
        graphQLErrors && graphQLErrors.some(e => e.extensions.status === '404')
      )
    },
    expiredOffer: ({ membershipOffer }) => {
      const state = membershipOffer.State.state

      return state === 'EXPIRED'
    },
    acceptedOffer: ({ membershipOffer }) => {
      const state = membershipOffer.State.state

      return state === 'ACCEPTED'
    },
    replacedOffer: ({ membershipOffer }) => {
      const state = membershipOffer.State.state

      return state === 'REPLACED'
    },
  },
  'signature-contract': {
    onboardingStarted,
    invalidState: ({ BusinessSignupStates }) => {
      return (
        !BusinessSignupStates.find(s => s.state === 'ACCOUNT_REGISTERED') ||
        BusinessSignupStates.find(s => s.state === 'CONTRACT_ACCEPTED')
      )
    },
  },
  'signature-payment': {
    onboardingStarted,
    invalidState: ({ BusinessSignupStates }) => {
      return !BusinessSignupStates.find(s => s.state === 'CONTRACT_ACCEPTED')
    },
  },
}

export const signatureErrorHandlers = {
  'signature-id': {
    unknownOffer: ({ error }) => {
      error({
        statusCode: 404,
        message: "Nous n'avons pas retrouvé votre page d'inscription",
      })
    },
    expiredOffer: ({ error, membershipOffer }) => {
      error({
        statusCode: 403,
        message: 'Offer is expired',
        membershipOffer,
      })
    },
    acceptedOffer: ({ error, membershipOffer }) => {
      error({
        statusCode: 403,
        message: 'Accepted is outdated',
        membershipOffer,
      })
    },
    replacedOffer: ({ redirect, membershipOffer }) => {
      redirect({
        name: 'signature',
        params: { id: membershipOffer.State.ReplacedBy.id },
      })
    },
  },
  'signature-contract': {
    onboardingStarted: onboardingStartedHandler,
    invalidState: context => {
      const { redirect, getRedirectPath, rules } = context
      const redirectPath = getRedirectPath(rules, context)
      redirect({ name: redirectPath || 'signature-payment' })
    },
  },
  'signature-payment': {
    onboardingStarted: onboardingStartedHandler,
    invalidState: context => {
      const { redirect, getRedirectPath, rules } = context
      const redirectPath = getRedirectPath(rules, context)
      redirect({ name: redirectPath })
    },
  },
}
