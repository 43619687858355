<template>
  <div
    class="row onboarding-progress"
    :class="{ 'onboarding-progress--completed': value === 100 }"
  >
    <div class="col-8">
      <BProgress :value="value" class="onboarding-progress__bar"></BProgress>
    </div>
    <div class="onboarding-progress__label col-4">{{
      $t('OnboardingProgress.completedAt', [value])
    }}</div>
  </div>
</template>
<script>
import { BProgress } from 'bootstrap-vue'

export default {
  components: { BProgress },
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
}
</script>
<style lang="scss">
.onboarding-progress {
  line-height: 15px;
  &__bar {
    height: 15px;
    background-color: $gray-light;
  }
  &__label {
    padding: 0;
    font-size: $font-size-sm;
  }

  &--completed {
    .onboarding-progress__label {
      font-weight: 600;
      color: $or;
    }
    .progress-bar {
      background-color: $or;
    }
  }
}
</style>
