<template>
  <Multiselect
    v-bind="$attrs"
    :value="innerValue"
    :name="name"
    :disabled="disabled"
    :data-name="name"
    :options="options"
    :track-by="valueField"
    :label="textField"
    :clear-on-select="false"
    :searchable="false"
    :show-no-results="false"
    :show-labels="false"
    :preselect-first="true"
    :allow-empty="false"
    placeholder=""
    :class="['multiselect--' + size]"
    v-on="inputListeners"
    @select="change"
    @remove="change"
  >
    <template #noOptions>{{ $t('noOptions') }}</template>
    <template #noResult>{{ $t('noResult') }}</template>
  </Multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  // Template dependencies
  components: {
    Multiselect,
  },

  // Composition
  mixins: [],

  // Interface
  /*
   * To avoid populate HTML element with props.
   * So let the inner component set HTML attributes.
   */
  inheritAttrs: false,

  /*
   * Using v-bind="$attrs" will respect the interface
   * but on tests props are not usable.
   * So need to declare the prop, then it is no more on $attrs.
   */
  props: {
    /*
     * v-model
     * @see https://v2.vuejs.org/v2/api/#v-model
     */
    value: {
      type: [Object, Array, String, Number],
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: '',
    },
    textField: {
      type: String,
      default: 'text',
    },
    valueField: {
      type: String,
      default: 'value',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
      validator: value =>
        [
          'sm',
          'md',
          // 'lg',
        ].includes(value),
    },
    // ariaInvalid: {
    //   type: [Boolean, String],
    //   default: false,
    // },
    // autofocus: {
    //   type: Boolean,
    //   default: false,
    // },
    // disabledField: {
    //   type: String,
    //   default: 'disabled',
    // },
    // form: {
    //   type: String,
    //   default: undefined,
    // },
    // htmlField: {
    //   type: String,
    //   default: 'html',
    // },
    // id: {
    //   type: String,
    //   default: undefined,
    // },
    // labelField: {
    //   type: String,
    //   required: false,
    //   default: 'label',
    // },
    // multiple: {
    //   type: Boolean,
    //   default: false,
    // },
    // optionsField: {
    //   type: String,
    //   default: 'options',
    // },
    // plain: {
    //   type: Boolean,
    //   default: false,
    // },
    // required: {
    //   type: Boolean,
    //   default: false,
    // },
    // selectSize: {
    //   type: Number,
    //   default: 0,
    // },
    // state: {
    //   type: Boolean,
    //   required: false,
    //   default: null,
    // },
  },

  // Local state
  data: () => ({
    // v-model
    innerValue: null,
  }),
  computed: {
    inputListeners() {
      const vm = this

      // `Object.assign` merges objects together to form a new object
      return Object.assign(
        {},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behaviour of some listeners.
        {
          // This ensures that the component works with v-model
          // Handles internal model changes.
          input(newVal) {
            if (newVal !== null) {
              newVal = newVal[vm.valueField]
            }

            vm.$emit('input', newVal)
          },
        }
      )
    },
  },

  // Listeners
  watch: {
    // v-model, Handles external model changes.
    value(newVal) {
      this.innerValue = this.findOption(newVal)
    },
  },
  created() {
    // v-model
    this.innerValue = this.findOption(this.value)
  },

  // Business rules
  methods: {
    change(selectedOption, id) {
      this.$emit('change', selectedOption[this.valueField])
    },
    findOption(value) {
      const selectedOptions = this.options.filter(option => {
        return option[this.valueField] === value
      })

      if (selectedOptions.length > 0) {
        return selectedOptions[0]
      }

      return null
    },
  },
}
</script>
