import { setContext } from 'apollo-link-context'

export default ({ sentry, getUserId }) =>
  setContext((operation, { headers }) => {
    let userId = null

    try {
      userId = getUserId()
    } catch (error) {
      // if (sentry()) {
      //   sentry().captureException(error)
      // } else {
      //   // eslint-disable-next-line no-console
      //   console.error(error)
      // }
      // eslint-disable-next-line no-console
      console.error(error)
    }

    if (userId) {
      return {
        headers: {
          ...headers,
          'Pro-User-ID': userId,
        },
      }
    }

    return {}
  })
