import Vue from 'vue'

import {
  getMatchedComponentsInstances,
  promisify,
  globalHandleError
} from './utils'

import NuxtError from '../src/layouts/error.vue'
import NuxtLoading from './components/nuxt-loading.vue'

import '../src/assets/scss/index.scss'

import '../node_modules/vue-multiselect/dist/vue-multiselect.min.css'

import '../node_modules/vue-datetime/dist/vue-datetime.css'

import _65a88512 from '../src/layouts/account.vue'
import _2c573a0e from '../src/layouts/agenda-settings.vue'
import _45ddb8b3 from '../src/layouts/community.vue'
import _522fb716 from '../src/layouts/default-guest.vue'
import _6f6c098b from '../src/layouts/default.vue'
import _77430317 from '../src/layouts/empty.vue'
import _77a03905 from '../src/layouts/large.vue'
import _263ac691 from '../src/layouts/onboarding.vue'
import _3ea8be5a from '../src/layouts/pro-profile.vue'
import _e0605016 from '../src/layouts/sign-up.vue'

const layouts = { "_account": _65a88512,"_agenda-settings": _2c573a0e,"_community": _45ddb8b3,"_default-guest": _522fb716,"_default": _6f6c098b,"_empty": _77430317,"_large": _77a03905,"_onboarding": _263ac691,"_pro-profile": _3ea8be5a,"_sign-up": _e0605016 }

export default {
  head: {"title":"Espace Pro","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico?v=1"},{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon-32x32.ico?v=1","sizes":"32x32"},{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon-16x16.ico?v=1","sizes":"16x16"},{"rel":"stylesheet","href":"https:\u002F\u002Ffonts.googleapis.com\u002Fcss?family=Source+Sans+Pro:400,400i,600","crossorigin":"anonymous"}],"script":[{"src":"https:\u002F\u002Fcdn.ckeditor.com\u002F4.12.1\u002Fstandard\u002Fckeditor.js","defer":true,"async":true,"crossorigin":"anonymous"},{"src":"\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id=GTM-K4QGKV8&l=dataLayer","async":true}],"style":[],"noscript":[{"hid":"gtm-noscript","innerHTML":"\u003Ciframe src=\"\u002F\u002Fwww.googletagmanager.com\u002Fns.html?id=GTM-K4QGKV8&l=dataLayer\" height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\"\u003E\u003C\u002Fiframe\u003E","pbody":true}],"__dangerouslyDisableSanitizersByTagID":{"gtm-noscript":["innerHTML"]}},

  render (h, props) {
    const loadingEl = h('NuxtLoading', { ref: 'loading' })

    if (this.nuxt.err && NuxtError.layout) {
      this.setLayout(
        typeof NuxtError.layout === 'function'
          ? NuxtError.layout(this.context)
          : NuxtError.layout
      )
    }

    const layoutEl = h(this.layout || 'nuxt')
    const templateEl = h('div', {
      domProps: {
        id: '__layout'
      },
      key: this.layoutName
    }, [ layoutEl ])

    const transitionEl = h('transition', {
      props: {
        name: 'layout',
        mode: 'out-in'
      },
      on: {
        beforeEnter (el) {
          // Ensure to trigger scroll event after calling scrollBehavior
          window.$nuxt.$nextTick(() => {
            window.$nuxt.$emit('triggerScroll')
          })
        }
      }
    }, [ templateEl ])

    return h('div', {
      domProps: {
        id: '__nuxt'
      }
    }, [
      loadingEl,

      transitionEl
    ])
  },

  data: () => ({
    isOnline: true,

    layout: null,
    layoutName: ''
  }),

  beforeCreate () {
    Vue.util.defineReactive(this, 'nuxt', this.$options.nuxt)
  },
  created () {
    // Add this.$nuxt in child instances
    Vue.prototype.$nuxt = this
    // add to window so we can listen when ready
    if (process.client) {
      window.$nuxt = this

      this.refreshOnlineStatus()
      // Setup the listeners
      window.addEventListener('online', this.refreshOnlineStatus)
      window.addEventListener('offline', this.refreshOnlineStatus)
    }
    // Add $nuxt.error()
    this.error = this.nuxt.error
    // Add $nuxt.context
    this.context = this.$options.context
  },

  mounted () {
    this.$loading = this.$refs.loading
  },
  watch: {
    'nuxt.err': 'errorChanged'
  },

  computed: {
    isOffline () {
      return !this.isOnline
    }
  },

  methods: {
    refreshOnlineStatus () {
      if (process.client) {
        if (typeof window.navigator.onLine === 'undefined') {
          // If the browser doesn't support connection status reports
          // assume that we are online because most apps' only react
          // when they now that the connection has been interrupted
          this.isOnline = true
        } else {
          this.isOnline = window.navigator.onLine
        }
      }
    },

    async refresh () {
      const pages = getMatchedComponentsInstances(this.$route)

      if (!pages.length) {
        return
      }
      this.$loading.start()

      const promises = pages.map((page) => {
        const p = []

        if (page.$options.fetch) {
          p.push(promisify(page.$options.fetch, this.context))
        }

        if (page.$options.asyncData) {
          p.push(
            promisify(page.$options.asyncData, this.context)
              .then((newData) => {
                for (const key in newData) {
                  Vue.set(page.$data, key, newData[key])
                }
              })
          )
        }

        return Promise.all(p)
      })
      try {
        await Promise.all(promises)
      } catch (error) {
        this.$loading.fail()
        globalHandleError(error)
        this.error(error)
      }
      this.$loading.finish()
    },

    errorChanged () {
      if (this.nuxt.err && this.$loading) {
        if (this.$loading.fail) {
          this.$loading.fail()
        }
        if (this.$loading.finish) {
          this.$loading.finish()
        }
      }
    },

    setLayout (layout) {
      if (!layout || !layouts['_' + layout]) {
        layout = 'default'
      }
      this.layoutName = layout
      this.layout = layouts['_' + layout]
      return this.layout
    },
    loadLayout (layout) {
      if (!layout || !layouts['_' + layout]) {
        layout = 'default'
      }
      return Promise.resolve(layouts['_' + layout])
    }
  },

  components: {
    NuxtLoading
  }
}
