<template>
  <div class="sidebar">
    <!-- Menu App Mobile -->
    <MenuApp v-if="isFromAppPro()" />
    <Navbar v-else />
    <div class="container-fluid">
      <div class="row">
        <Menu
          class="col-md-4 col-xl-3 sidebar__menu d-none d-md-block"
          :backlink="menu.backlink"
          :items="menu.items"
        >
          <template #profile-picture>
            <ProfilePicture class="profile-picture" />
          </template>
        </Menu>
        <div class="col-12 col-xl-7 col-md-8 sidebar__main">
          <nuxt-link
            v-if="!isFromAppPro()"
            :to="{ name: 'index' }"
            class="d-block d-md-none mb-5 backlink d-flex align-items-center"
          >
            <BackIcon class="back-icon" />
            <ProfilePicture class="profile-picture" />
            {{ $t('InsightPage.seeMyPage') }}
          </nuxt-link>

          <nuxt />
        </div>
      </div>
    </div>
    <GlobalErrorReporter />
  </div>
</template>

<script>
import MenuApp from '../components/app-pro/menu-app.vue'
import Navbar from '@/components/navbar'
import Menu from '@/components/sidebar-menu'
import BackIcon from '@/assets/icons/arrow-left.svg'
import ProfilePicture from '@/components/profile-picture'
import GlobalErrorReporter from '@/components/global-error-reporter'
import { appPro } from '@/mixins/app-pro'

export default {
  components: {
    MenuApp,
    Navbar,
    Menu,
    BackIcon,
    ProfilePicture,
    GlobalErrorReporter,
  },
  mixins: [appPro],
  data: () => ({
    menu: {},
  }),
  beforeMount() {
    this.menu = {
      backlink: {
        name: this.$t('InsightPage.seeMyPage'),
        route: 'pro-profile',
      },
      items: [
        {
          name: this.$t('InsightPage.title'),
          route: 'pro-profile-insight',
        },
        {
          name: this.$t('ReviewsPage.title'),
          route: 'pro-profile-reviews',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.backlink {
  font-size: $font-size-sm;
}
.sidebar {
  &__menu {
    min-height: 100vh;
    margin-top: rem(-20px);
  }

  &__main {
    padding-top: rem(26px);

    @include media-breakpoint-up(md) {
      padding-left: rem(70px);
    }
  }
}
.profile-picture {
  width: rem(51px);
  height: rem(51px);
  margin: 0 rem(10px);
}
</style>
