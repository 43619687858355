/* eslint-disable unicorn/filename-case */

import { Observable } from 'apollo-link'
import { refreshToken, isAnonymousToken, TOKEN_TYPE } from '@/services/auth'
import { setError } from '@/graphql/app.gql'

export default class RefreshTokenObservableBuilder {
  constructor({ apolloClient, apolloHelpers, triggerAppError }) {
    this._triggerAppError = triggerAppError
    this._apolloClient = apolloClient
    this._apolloHelpers = apolloHelpers
  }

  /**
   * @public
   */
  buildObservable(operation, forward) {
    return new Observable(observer => {
      let retrySub = null

      const retryOperationWithAuthToken = token => {
        this.setAuthTokenOnOperation(token, operation)

        return retryOperation()
      }

      const retryOperation = () => {
        return forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        })
      }

      const sub = forward(operation).subscribe({
        next(result) {
          observer.next(result)
        },
        error: exception => {
          if (!this.exceptionIsUnauthenticated(exception)) {
            observer.error(exception)

            return
          }

          const token = this.getAuthTokenFromOperation(operation)

          if (isAnonymousToken(token)) {
            this.handleFailureWithAnonymousToken(observer, exception, operation)

            return
          }

          this.getNewTokenFromOldToken(token)
            .then(newToken => {
              retrySub = retryOperationWithAuthToken(newToken)
            })
            .catch(() => {
              this.handleRefreshTokenFailure(observer, exception)
            })
        },
        complete() {
          observer.complete()
        },
      })

      return () => {
        if (sub) {
          sub.unsubscribe()
        }

        if (retrySub) {
          retrySub.unsubscribe()
        }
      }
    })
  }

  /**
   * @private
   */
  getNewTokenFromOldToken(oldToken) {
    return refreshToken({
      done: token => {
        this._apolloHelpers.onLogin(
          token,
          undefined,
          undefined,
          /* skipResetStore */ true
        )
      },
      apolloClient: this._apolloClient,
      oldToken,
    })
  }

  /**
   * @private
   */
  handleRefreshTokenFailure(observer, exception) {
    this.triggerUnauthenticatedAppError()

    observer.error(exception)
  }

  /**
   * @private
   */
  handleFailureWithAnonymousToken(observer, exception, operation) {
    if (operation.operationName === 'refreshToken') {
      observer.error(exception)

      return
    }

    this.displayGlobalError().then(() => {
      this.triggerUnauthenticatedAppError()

      observer.error(exception)
    })
  }

  /**
   * @private
   */
  triggerUnauthenticatedAppError() {
    this._triggerAppError({
      statusCode: 401,
    })
  }

  /**
   * @private
   */
  getAuthTokenFromOperation(operation) {
    const headers = operation.getContext().headers
    const authorization = headers.authorization

    if (authorization) {
      const tokenType = authorization.substring(0, TOKEN_TYPE.length + 1)

      if (tokenType === `${TOKEN_TYPE} `) {
        return authorization.replace(tokenType, '')
      }
    }
  }

  displayGlobalError() {
    return this._apolloClient.mutate({
      mutation: setError,
      variables: { value: true },
    })
  }

  /**
   * @private
   */
  setAuthTokenOnOperation(token, operation) {
    const oldHeaders = operation.getContext().headers

    operation.setContext({
      headers: {
        ...oldHeaders,
        authorization: `${TOKEN_TYPE} ${token}`,
      },
    })
  }

  /**
   * @private
   */
  exceptionIsUnauthenticated(exception) {
    if (this.exceptionIsServerError(exception)) {
      return this.responseIsUnauthenticated(exception.response)
    }

    return false
  }

  /**
   * @private
   */
  exceptionIsServerError(exception) {
    return exception.response !== undefined
  }

  /**
   * @private
   */
  responseIsUnauthenticated(response) {
    return response.status === 401
  }
}
