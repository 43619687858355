import { BFormGroup } from 'bootstrap-vue'
import has from 'lodash/has'
import debounce from 'lodash/debounce'
import FormButtons from '@/components/form-buttons'
import RemoveLink from '@/components/remove-link'
import FormErrors from '@/components/form-errors'
import {
  getFailedValidations,
  getMutationForbiddenErrors,
} from '@/services/error'
import trackEvent from '@/services/track-event'

export const Form = {
  components: {
    BFormGroup,
    FormButtons,
    FormErrors,
    RemoveLink,
  },
  props: {
    onSave: {
      type: Function,
      default: () => {},
    },
    onCancel: {
      type: Function,
      default: () => {},
    },
    onRemove: {
      type: Function,
      default: () => {},
    },
    refetchQueries: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    formSaving: false,
    formDeleting: false,
    fieldSaving: [],
    backendFieldMap: {},
    storageFieldMap: {},
    unknownErrors: [],
    graphQLErrors: [],
  }),
  methods: {
    sendSubmitEvent(status) {
      if (!this.$options.dataLayer) {
        return
      }

      const { action, formName } = this.$options.dataLayer

      trackEvent(`form.${formName}.${action}.${status}`)
    },
    _errorHandler(error) {
      const { graphQLErrors, networkError } = error

      if (
        networkError &&
        networkError.response &&
        networkError.response.status === 401
      ) {
        throw error
      }

      this.graphQLErrors = graphQLErrors || []

      const validationErrors = getFailedValidations(graphQLErrors)
      const mutationForbiddenErrors = getMutationForbiddenErrors(graphQLErrors)

      if (validationErrors.length > 0) {
        this.setBackendValidationErrors(validationErrors)
      } else if (mutationForbiddenErrors.length > 0) {
        this.pushUnknownErrors(mutationForbiddenErrors)
      } else {
        this.logError(error)

        this.unknownErrors.push({
          field: 'global',
          message:
            'Une erreur est survenue, veuillez reesayer ou contactez-nous',
        })
      }
    },
    async remove() {
      if (this.formDeleting) {
        return false
      }

      this.formDeleting = true
      this.unknownErrors = []

      const onRemoveMethod =
        typeof this.onRemoveOverride === 'function'
          ? this.onRemoveOverride
          : this.onRemove

      await onRemoveMethod(this.form, this.$props)
        .catch(error => {
          this._errorHandler(error)
          this.formDeleting = false
        })
        .then(() => {
          this.formDeleting = false
          if (this.onCancel) {
            this.onCancel()
          }
        })
    },
    isFieldSaving(field) {
      return this.fieldSaving.includes(field)
    },

    submitField: debounce(async function(field) {
      const observerRef = this.$options.observerRef || 'observer'
      await this.$refs[observerRef].validate(field)

      const isValid =
        !this.$refs[observerRef].ctx.errors[field] ||
        this.$refs[observerRef].ctx.errors[field].length === 0

      if (!isValid) {
        return false
      }

      this.fieldSaving.push(field)

      const onSaveMethod =
        typeof this.onSaveOverride === 'function'
          ? this.onSaveOverride
          : this.onSave
      await onSaveMethod(this.form, this.$props).catch(error => {
        this._errorHandler(error)
      })

      // only remove on result
      const savingIndex = this.fieldSaving.findIndex(item => item === field)
      this.fieldSaving = this.fieldSaving.filter(
        (item, index) => index !== savingIndex
      )
    }, 300),

    async submit() {
      if (this.formSaving) {
        return false
      }

      this.formSaving = true
      this.unknownErrors = []
      this.graphQLErrors = []

      const observerRef = this.$options.observerRef || 'observer'

      let isValid = await this.$refs[observerRef].validate()

      if (!isValid) {
        this.sendSubmitEvent('failed')
        this.formSaving = false

        return false
      }

      const onSaveMethod =
        typeof this.onSaveOverride === 'function'
          ? this.onSaveOverride
          : this.onSave
      await onSaveMethod(this.form, this.$props).catch(error => {
        this._errorHandler(error)
        this.sendSubmitEvent('failed')

        isValid = false
      })

      this.formSaving = false

      if (isValid) {
        this.sendSubmitEvent('succeed')
      }

      if (isValid && this.onCancel) {
        this.onCancel()
      }
    },

    /**
     * Set backend validation errors
     *
     * @param {array} errors
     */
    setBackendValidationErrors(errors) {
      const observerErrors = {}

      for (const [, vError] of Object.entries(errors)) {
        const field = this.findFieldFromBackendPath(vError.field)

        if (this.fieldNotExistsOnForm(field)) {
          this.unknownErrors.push(vError)

          this.logError(
            'unknown validation error for field ' + JSON.stringify(vError)
          )

          continue
        }

        if (typeof observerErrors[field] === 'undefined') {
          observerErrors[field] = []
        }
        observerErrors[field].push(vError.message)
      }

      this.$refs.observer.setErrors(observerErrors)
    },

    logError(message) {
      try {
        // this.$sentry.captureMessage(message)
      } catch (error) {}
    },

    /**
     * Find field from backend path
     *
     * @param {string} backendField
     * @return {string}
     * @throws Error
     */
    findFieldFromBackendPath(backendField) {
      const lastPathPart = backendField.substr(
        backendField.lastIndexOf('.') + 1
      )

      if (!backendField.includes('input.')) {
        return lastPathPart
      }
      // get string after "input."
      const path = backendField.substr(backendField.indexOf('input.') + 6)
      let field = path

      // easy way
      field = this.backendFieldMap[path] || field

      // no result, try to match the last path of the path with the field map
      if (field === path) {
        field = this.backendFieldMap[lastPathPart] || field
      }

      // still no result, so send the last part
      if (field === path) {
        return lastPathPart
      }

      return field
    },

    pushUnknownErrors(errors) {
      for (const [, error] of Object.entries(errors)) {
        this.unknownErrors.push(error)
      }
    },

    fieldNotExistsOnForm(field) {
      field = this.storageFieldMap[field] || field

      return has(this.form, field) === false
    },
  },
}
