import { render, staticRenderFns } from "./pro-profile.vue?vue&type=template&id=860a65d0&scoped=true&"
import script from "./pro-profile.vue?vue&type=script&lang=js&"
export * from "./pro-profile.vue?vue&type=script&lang=js&"
import style0 from "./pro-profile.vue?vue&type=style&index=0&id=860a65d0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "860a65d0",
  null
  
)

export default component.exports