<template>
  <div>
    <div>
      <nuxt />
    </div>
    <GlobalErrorReporter />
  </div>
</template>

<script>
import GlobalErrorReporter from '@/components/global-error-reporter'

export default {
  components: {
    GlobalErrorReporter,
  },
}
</script>
