const COLORS = {
  YELLOW: '#f0cf27',
  GREEN: '#419e13',
  RED: '#e43434',
  VIOLET: '#f273c0',
  BLUE: '#2073cf',
  GRAY: '#A9B6BC',
  WHITE: '#f2f4f5',
}

const DEFAULT_COLOR = COLORS.BLUE
const PRIVATE_COLOR = COLORS.GRAY

const LIGHTER_COLORS = {
  YELLOW: '#fcf7de',
  GREEN: '#e2f0db',
  RED: '#fae0e0',
  VIOLET: '#eedcf5',
  BLUE: '#ddeaf7',
  GRAY: '#f2f4f5',
  WHITE: '#FFFFFF',
}

const hexToColorName = hex => {
  for (const name in COLORS) {
    if (String(hex).toLocaleLowerCase() === COLORS[name].toLocaleLowerCase()) {
      return name
    }
  }
}

export default COLORS
export { DEFAULT_COLOR, PRIVATE_COLOR, COLORS, LIGHTER_COLORS, hexToColorName }
