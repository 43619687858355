<template>
  <div class="appointment-purpose-icon">
    <span
      class="appointment-purpose-icon__badge"
      :style="{ background: badgeColor }"
    ></span>
    <span
      v-if="title"
      class="appointment-purpose-icon__label"
      :class="{ 'font-weight-bold': bold }"
      :style="{ color: textColor }"
      >{{ title }}</span
    >
  </div>
</template>

<script>
const DEFAULT_COLOR = '#2073CF'
const PRIVATE_COLOR = '#A9B6BC'

export default {
  props: {
    color: { required: false, type: String, default: null },
    title: { required: false, type: String, default: null },
    bold: { required: false, type: Boolean, default: false },
    colorized: { required: false, type: Boolean, default: false },
    private: { required: false, type: Boolean, default: false },
  },
  computed: {
    badgeColor() {
      let color = this.color || DEFAULT_COLOR
      if (this.private) {
        color = PRIVATE_COLOR
      }
      return color
    },
    textColor() {
      return this.colorized ? this.badgeColor : 'inherit'
    },
  },
}
</script>

<style lang="scss" scoped>
.appointment-purpose-icon {
  &__badge {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 5px;
    border-radius: 9px;
  }
}
</style>
