<template>
  <div class="picture" :style="{ backgroundImage: `url('${pictureUri}')` }" />
</template>

<script>
import { getProfilePicture } from './profile-picture.gql'

export default {
  data: () => ({
    pictureUri: null,
  }),
  apollo: {
    pictureUri: {
      query: getProfilePicture,
      update: data => data.me.Business.Profile.ProfilePicture.uri,
    },
  },
}
</script>

<style lang="scss" scoped>
.picture {
  display: inline-block;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}
</style>
