/* global gapi */

import Vue from 'vue'

const gauthOption = {
  clientId: process.env.GOOGLE_CLIENT_ID_PRACTICIAN,
  scope: 'https://www.googleapis.com/auth/calendar.readonly',
  prompt: 'consent',
  response_type: 'permission code',
}

const googleAuth = (function() {
  function installClient() {
    const apiUrl = 'https://apis.google.com/js/api.js'
    return new Promise(resolve => {
      const script = document.createElement('script')
      script.src = apiUrl
      script.onreadystatechange = script.addEventListener('load', function() {
        if (!script.readyState || /loaded|complete/.test(script.readyState)) {
          setTimeout(function() {
            resolve()
          }, 500)
        }
      })
      document.querySelectorAll('head')[0].append(script)
    })
  }

  function initClient() {
    return new Promise(resolve => {
      window.gapi.load('auth2', () => {
        resolve(window.gapi)
      })
    })
  }

  function Auth() {
    if (!(this instanceof Auth)) return new Auth()

    this.isInit = false

    this.load = () => {
      installClient()
        .then(() => {
          return initClient()
        })
        .then(gapi => {
          this.isInit = true
        })
    }

    this.authorize = () => {
      return new Promise((resolve, reject) => {
        if (!this.isInit) {
          reject(new Error('Google API is not initialized'))
          return
        }

        gapi.auth2.authorize(gauthOption, function(response) {
          if (response.error) {
            reject(response.error)
            return
          }

          resolve(response)
        })
      })
    }
  }

  return new Auth()
})()

function installGoogleAuthPlugin(Vue) {
  // Install Vue plugin
  Vue.googleAPI = googleAuth
  Object.defineProperties(Vue.prototype, {
    $googleAPI: {
      get() {
        return Vue.googleAPI
      },
    },
  })
  Vue.googleAPI.load()
}

Vue.use(installGoogleAuthPlugin)
