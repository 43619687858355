<template>
  <button
    class="btn"
    :class="`btn-${variant}`"
    :disabled="disabled"
    v-on="$listeners"
  >
    <BSpinner v-if="loading" small type="grow"></BSpinner>
    <span v-show="!loading">{{ $t(text) }}</span>
    <span v-show="loading">{{ $t('button.loading') }}</span>
  </button>
</template>
<script>
import { BSpinner } from 'bootstrap-vue'

export default {
  components: { BSpinner },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: 'button.save',
    },
    variant: {
      type: String,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
