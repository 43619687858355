import { getUserIdFromContext } from '@/services/auth'

export default ({ app, env, route }) => {
  window.dataLayer = window.dataLayer || []
  const userId = fetchUserId(app)
  const message = {
    app: {
      version: env.APP_VERSION || null,
    },
    user: {
      id: userId + '',
    },
  }

  if (userId) {
    window.dataLayer.push({
      event: 'user_identity_updated',
      ...message,
    })
  }

  window.dataLayer.push({
    event: 'nuxtRoute',
    pageType: 'PageView',
    routeName: route.name,
    pageUrl: route.fullPath,
    ...message,
  })
}

const fetchUserId = app => {
  try {
    return getUserIdFromContext(app.context)
  } catch (error) {
    if (app.$sentry) {
      app.$sentry.captureException(error)
    } else {
      // eslint-disable-next-line no-console
      console.error(error)
    }

    return null
  }
}
