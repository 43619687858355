<template>
  <b-collapse :id="$attrs.id" v-model="visible" class="wrapper">
    <div class="menu">
      <div class="menu__header">
        <LogoSmall class="menu__header__logo" />
        Espace Pro
        <CloseIcon v-b-toggle="$attrs.id" class="ml-auto menu__header__close" />
      </div>
      <div class="menu__wrapper d-flex _justify-content-between flex-column">
        <div
          v-if="Object.keys(finalLinks).length"
          class="menu__links menu__links--top"
        >
          <template v-for="link in finalLinks">
            <div
              v-if="link.route === 'agenda'"
              :key="link.label"
              class="menu__dropdown"
            >
              <nuxt-link
                :to="link.route ? { name: link.route } : ''"
                class="menu__link"
                :active-class="
                  `menu__link--active-${link.route} menu__link--active`
                "
              >
                <component
                  :is="link.icon"
                  v-if="link.icon"
                  class="menu__link__icon"
                  :class="`menu__link__icon--${link.route}`"
                />
                {{ $t(link.label) }}
                <component
                  :is="$options.ArrowDownIcon"
                  class="menu__link__caret"
                />
              </nuxt-link>
              <div v-if="link.children" class="menu__sublinks">
                <nuxt-link
                  v-for="childLink in link.children"
                  :key="childLink.label"
                  :to="childLink.route ? { name: childLink.route } : ''"
                  class="menu__sublink"
                  exact-active-class="menu__sublink--active"
                >
                  {{ $t(childLink.label) }}
                </nuxt-link>
              </div>
            </div>
            <nuxt-link
              v-else-if="link.route"
              :key="link.label"
              :to="link.route ? { name: link.route } : ''"
              class="menu__link"
              :active-class="`menu__link--active-${link.route}`"
              exact-active-class="menu__link--active"
            >
              <component
                :is="link.icon"
                v-if="link.icon"
                class="menu__link__icon"
                :class="`menu__link__icon--${link.route}`"
              />
              {{ $t(link.label) }}
            </nuxt-link>
            <a
              v-else
              :key="link.label"
              :href="link.url"
              class="menu__link"
              :target="link.target ? '_blank' : '_self'"
            >
              {{ $t(link.label) }}
            </a>
          </template>
        </div>

        <div
          class="menu__links menu__links--footer"
          :class="{
            'menu__links--no-top': Object.keys(finalLinks).length === 0,
          }"
        >
          <div class="menu__dropdown">
            <div
              v-b-toggle.account-links
              class="menu__link"
              :class="{
                'menu__link--active': String($route.name).includes('account'),
              }"
            >
              <component
                :is="$options.AccountIcon"
                class="menu__link__icon show"
                :class="`menu__link__icon--account`"
              />
              {{ username }}
              <component
                :is="$options.ArrowDownIcon"
                class="menu__link__caret"
              />
            </div>
            <b-collapse
              id="account-links"
              class="menu__sublinks"
              :visible="String($route.name).includes('account')"
            >
              <template v-for="link in userLinks">
                <nuxt-link
                  v-if="link.route"
                  :key="link.label"
                  :to="{ name: link.route }"
                  class="menu__sublink"
                  exact-active-class="menu__sublink--active"
                >
                  {{ $t(link.label) }}
                </nuxt-link>

                <a
                  v-else
                  :key="link.label"
                  :href="link.url"
                  target="blank"
                  class="menu__sublink"
                >
                  {{ $t(link.label) }}
                </a>
              </template>
            </b-collapse>
          </div>
          <a
            class="menu__link"
            href="https://aide.medoucine.com"
            target="_blank"
          >
            <HelpIcon class="menu__link__icon menu__link__icon--help show" />

            Aide
          </a>
        </div>
      </div>
    </div>
    <div v-b-toggle="'navbar-collapse'" class="overlay" />
  </b-collapse>
</template>
<script>
import { BCollapse, VBToggle } from 'bootstrap-vue'
import HelpIcon from '@/assets/icons/help.svg'
import CloseIcon from '@/assets/icons/croix.svg'
import LogoSmall from '@/assets/icons/logo-white-small.svg'
import ArrowDownIcon from '@/assets/icons/arrow-down.svg'
import AccountIcon from '@/assets/icons/mes-infos.svg'

export default {
  ArrowDownIcon,
  AccountIcon,
  components: {
    'v-b-toggle': VBToggle,
    'b-collapse': BCollapse,
    HelpIcon,
    CloseIcon,
    LogoSmall,
  },
  props: {
    username: {
      type: String,
      default: '',
    },
    links: {
      type: Object,
      default: () => {},
    },
    userLinks: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    visible: false,
    agendaIsVisible: false,
  }),
  computed: {
    finalLinks() {
      if (Object.keys(this.links).length === 0 || this.username === '') {
        return {}
      }

      const links = {
        home: {
          label: 'Navbar.link.home',
          route: 'index',
        },
        ...this.links,
      }

      links.reservations.children = [
        {
          label: 'Navbar.link.myAppointments:long',
          route: 'agenda',
        },
        {
          label: 'AgendaSettings.menu.workEnvironmentLabel',
          route: 'agenda-settings-work-environment',
        },
        {
          label: 'AgendaSettings.menu.motiveLabel',
          route: 'agenda-settings-appointment-formula',
        },
        {
          label: 'AgendaSettings.menu.agendaSyncLabel',
          route: 'agenda-settings-google-calendars',
        },
      ]

      return links
    },
  },
  watch: {
    $route(to) {
      this.visible = false
    },
  },
}
</script>
<style lang="scss" scoped>
.wrapper {
  position: fixed;
  top: 0;
  left: -300px;
  z-index: 9999;
  width: 300px;
  min-width: 300px;
  // height: 100%;
  height: 100vh;
  min-height: 100%;
  overflow: hidden;
  -webkit-transition-property: left, visibility;
  transition-property: left, visibility;

  &.show {
    left: 0;
  }
}

.menu {
  position: relative;
  z-index: 999;
  height: 100%;
  overflow: auto;
  font-size: rem(18px);
  font-weight: bold;
  background: $cactus-500;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);

  &__links {
    &--top {
      margin-bottom: 50px;
    }
    &--footer {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
    }
    &--no-top {
      border: 0 !important;
    }
  }

  &__sublinks {
    padding-bottom: 15px;
    margin-top: 12px;
    margin-bottom: 5px;
    margin-left: 12px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  &__sublink {
    display: block;
    padding-left: 8px;
    margin: 12px;
    margin-left: 0;
    font-size: rem(16px);
    font-weight: normal;
    color: #fff;
    text-decoration: none !important;

    @include hover-focus {
      font-weight: bold;
    }

    &--active {
      font-weight: bold;
      border-left: 4px solid #fff;
    }
  }

  &__link {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    padding: 5px 12px;
    margin: 5px 0;
    color: #fff;
    text-decoration: none !important;
    cursor: pointer;

    @include hover-focus {
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 3px;
    }

    &--active {
      background: rgba(255, 255, 255, 0.2);
      border-radius: 3px;
    }

    &__caret {
      margin-left: auto !important;
    }

    &__icon {
      display: none;
      height: 20px;
      margin-right: 5px;

      .menu__link--active &,
      .menu__link:focus &,
      .menu__link:active &,
      &.show {
        display: inline;
      }

      &--customers /deep/ path {
        fill: #fff;
      }

      &--community /deep/ path {
        fill: #fff;
      }
      &--help /deep/ path {
        fill: #fff;
      }
      &--agenda /deep/ path {
        stroke: #fff;
      }
    }
  }

  &__header {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 50px;
    padding: $navbar-padding-y $navbar-padding-x;
    color: #fff;
    background-color: $cactus-500;

    &__logo {
      height: 28px;
      margin-right: 15px;
    }
    &__close /deep/ {
      width: 24px;
      height: 24px;
      cursor: pointer;

      path {
        stroke: #fff;
      }
    }
  }
  &__wrapper {
    height: 100%;
    padding: 20px;
  }
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  display: block;
  width: 100vw;
  height: 100vh;
  overflow: hidden; // should also be on body
  background: rgba(81, 93, 99, 0.81);
  opacity: 0;
  transition: all 0.5s ease-in-out;

  .show & {
    opacity: 1;
  }
}
</style>
