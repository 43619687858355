<template>
  <div class="time-picker">
    <AppFormSelect
      v-model="time.hour"
      :options="hourOptions"
      name="hour"
      :disabled="disabled"
      class="time-picker__select"
    />
    :
    <AppFormSelect
      v-model="time.minute"
      :options="minuteOptions"
      name="minute"
      :disabled="disabled"
      class="time-picker__select"
    />
  </div>
</template>
<script>
import AppFormSelect from '@/components/app-form-select'
import { getHourWithOffset } from '@/services/util'

export default {
  components: { AppFormSelect },
  props: {
    date: { required: true, type: Date },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      default: null,
      type: String,
    },
  },
  data: () => ({
    time: {
      hour: '00',
      minute: '00',
    },
  }),
  computed: {
    hourOptions() {
      return this.generateDuration(1, 23)
    },
    minuteOptions() {
      return this.generateDuration(5, 55)
    },
  },
  watch: {
    time: {
      handler(val) {
        this.emitInput()
      },
      deep: true,
    },
    date: {
      handler(val) {
        this.emitInput()
      },
    },
  },
  created() {
    if (this.value) {
      // Convert the date string to local time zone then extract hour and minutes.
      this.time = {
        hour: this.$filters.date(this.value, 'HH'),
        minute: this.$filters.date(this.value, 'mm'),
      }
    } else {
      this.time = this.generateNextTime()
    }
  },
  methods: {
    generateDuration(step, max) {
      let current = 0
      const options = []
      while (current <= max) {
        const formattedValue = current.toString().padStart(2, '0')
        options.push({
          text: formattedValue,
          value: formattedValue,
        })
        current += step
      }

      return options
    },
    generateNextTime() {
      const epsilon = 5
      const nextDate = new Date()
      const time = {
        hour: nextDate.getHours(),
        minute: nextDate.getMinutes(),
      }

      // Takes the next minute value with comply with epsilon constraint.
      nextDate.setMinutes(time.minute + epsilon - (time.minute % epsilon))

      time.minute = nextDate.getMinutes()
      time.hour = nextDate.getHours()

      // Ensure that hour and minute are on 2 characters.
      Object.keys(time).forEach(field => {
        time[field] = time[field].toString().padStart(2, '0')
      })

      return time
    },
    emitInput() {
      this.$emit(
        'input',
        getHourWithOffset(this.date, this.time.hour, this.time.minute)
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.time-picker__select {
  width: 70px;
}
.time-picker {
  display: flex;
  align-items: center;
}
</style>
