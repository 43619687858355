var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hierarchycal-multiselect"},[_c('Multiselect',_vm._g(_vm._b({attrs:{"multiple":true,"show-labels":false,"show-pointer":false,"close-on-select":false},scopedSlots:_vm._u([{key:"noOptions",fn:function(){return [_vm._v(_vm._s(_vm.$t('noOptions')))]},proxy:true},{key:"noResult",fn:function(){return [_vm._v(_vm._s(_vm.$t('noResult')))]},proxy:true},{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(_vm.$t('HierarchycalSelect.selectedOptions', [values.length]))+"\n      ")]):_vm._e(),_vm._v(" "),_c('template',{slot:"tags"})]}},{key:"option",fn:function(ref){
var search = ref.search;
var option = ref.option;
return [_c('span',{attrs:{"data-position":option.position}},[_c('AddIcon',{staticClass:"unselected-icon",attrs:{"width":"18","height":"18"}}),_vm._v(" "),_c('SelectedIcon',{staticClass:"selected-icon",attrs:{"width":"18"}}),_vm._v("\n        "+_vm._s(_vm.getOptionLabel(option))+"\n      ")],1)]}}])},'Multiselect',{
      options: _vm.options,
      trackBy: _vm.trackBy,
      label: _vm.label,
      placeholder: _vm.placeholder,
      id: _vm.id,
      value: _vm.value,
      name: _vm.name,
    },false),_vm.$listeners),[_vm._v(" "),_vm._v(" "),_vm._v(" "),_vm._v("\n    >")]),_vm._v(" "),(_vm.bottomTags)?_c('div',{staticClass:"tags"},[(_vm.sortable)?_c('SlickList',{staticClass:"tags",attrs:{"use-drag-handle":true},on:{"sort-end":_vm.onSort},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},_vm._l((_vm.innerValue),function(option,index){return _c('SlickItem',{key:index,staticClass:"tags__item",attrs:{"index":index,"item":option}},[_c('Tag',{attrs:{"removable":""},on:{"remove":function($event){return _vm.removeElement(option)}}},[_c('DragIcon',{directives:[{name:"handle",rawName:"v-handle"}],staticClass:"tags__handle"}),_vm._v(_vm._s(index + 1)+"-\n          "+_vm._s(_vm.getOptionLabel(option)))],1)],1)}),1):_vm._e(),_vm._v(" "),(!_vm.sortable)?_c('div',_vm._l((_vm.value),function(option,index){return _c('Tag',{key:index,staticClass:"tags__item",attrs:{"removable":""},on:{"remove":function($event){return _vm.removeElement(option)}}},[_vm._v(_vm._s(_vm.getOptionLabel(option)))])}),1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }