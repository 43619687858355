<template>
  <div class="link">
    <a href="#" v-on="$listeners">
      <BSpinner v-if="loading" small type="grow"></BSpinner>
      <span v-show="!loading">{{ $t(text) }}</span>
      <span v-show="loading">{{ $t('button.loading') }}</span>
    </a>
  </div>
</template>
<script>
import { BSpinner } from 'bootstrap-vue'

export default {
  components: { BSpinner },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: 'button.save',
    },
  },
}
</script>
<style lang="scss" scoped>
.link {
  margin: rem(20px) 0;
  font-weight: bold;
  text-align: center;
  text-decoration: underline;
  a {
    color: var(--danger);
  }
}
</style>
