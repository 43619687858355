<template>
  <nuxt-link
    :to="{ name: route }"
    class="item"
    :class="'item--' + state"
    active-class="item--active"
    :disabled="state === 'disabled'"
    exact
  >
    <CompletedFlag v-if="completed" class="item__completed-flag" />
    <div v-else class="item__icon"></div>
    <div class="item__label">{{ $t(label) }}</div>
  </nuxt-link>
</template>

<script>
import CompletedFlag from '@/assets/icons/checked.svg'

export default {
  components: { CompletedFlag },
  inheritAttrs: false,
  props: {
    route: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: [Object, String],
      required: false,
    },
    state: {
      type: String,
      default: 'default',
      validator: state => {
        return ['disabled', 'active', 'default'].includes(state)
      },
    },
    completed: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
