<template>
  <div class="sidebar">
    <Navbar />
    <div class="container-fluid">
      <div class="row">
        <Menu
          class="col-md-4 col-xl-3 sidebar__menu d-none d-md-block"
          :backlink="menu.backlink"
          :items="menu.items"
        />
        <div class="col-12 col-xl-7 col-md-8 sidebar__main">
          <nuxt-link
            :to="{ name: 'index' }"
            class="d-block d-md-none mb-5 backlink"
          >
            <BackIcon class="back-icon" />
            {{ $t('backToHome') }}
          </nuxt-link>

          <nuxt />
        </div>
      </div>
    </div>
    <GlobalErrorReporter />
  </div>
</template>

<script>
import Navbar from '@/components/navbar'
import Menu from '@/components/sidebar-menu'
import BackIcon from '@/assets/icons/arrow-left.svg'
import GlobalErrorReporter from '@/components/global-error-reporter'

const MENU = {
  backlink: {
    name: 'Retour à l‘accueil',
    route: 'index',
  },
  items: [
    {
      name: 'Compte',
      route: 'account',
    },
    {
      name: 'Informations administratives',
      route: 'account-administrative',
    },
    {
      name: 'Contrat et facturation',
      route: 'account-contract',
    },
  ],
}

export default {
  components: {
    Navbar,
    Menu,
    BackIcon,
    GlobalErrorReporter,
  },
  data: () => ({
    menu: MENU,
  }),
}
</script>

<style lang="scss" scoped>
.backlink {
  font-size: $font-size-sm;
}
.sidebar {
  &__menu {
    min-height: 100vh;
    margin-top: rem(-20px);
  }

  &__main {
    padding-top: rem(26px);
    padding-bottom: spacing(5);

    @include media-breakpoint-up(md) {
      padding-left: rem(70px);
    }
  }
}
</style>
