<template>
  <div class="container">
    <MembershipOfferErrors
      v-if="error.membershipOffer"
      :membership-offer="error.membershipOffer"
    />
    <div v-else-if="isOffline">
      <div class="text-center" style="width:100%; margin-top:100px;">
        <h1 class="title">
          Aucune connexion internet
        </h1>
        <p class="desc"
          >Veuillez vérifier votre connexion puis recharger la page.
        </p>
        <div class="mt-5">
          <button class="btn btn-primary" @click.prevent="reload"
            >Recharger la page</button
          >
        </div>
      </div>
    </div>
    <div v-else-if="isFromUnauthorized">
      <div class="main-content">
        <h1 class="text-center title">
          Se reconnecter
        </h1>
        <div class="form-content mx-auto mt-4">
          <LoginForm @authenticated="authenticated" />
        </div>
      </div>
    </div>
    <div v-else>
      <div class="text-center" style="width:100%; margin-top:100px;">
        <h1 v-if="statusCode === 404" class="title">
          Page non trouvée
        </h1>
        <h1 v-else class="title">
          Oops! Vous avez rencontré une erreur.
        </h1>
        <p class="desc"
          >Nous sommes désolés pour ce problème, nous avons été alertés.
          <br />Essayez maintenant de recharger la page.
        </p>
        <div class="mt-5">
          <button class="btn btn-primary" @click.prevent="reload"
            >Recharger la page</button
          ><br />
          ou
          <br />
          <nuxt-link
            :to="{ name: 'index' }"
            class="btn btn-outline-primary btn-sm"
          >
            Aller à l'accueil
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MembershipOfferErrors from '@/components/errors/membership-offer-errors'
import LoginForm from '@/components/forms/login-form'

export default {
  components: {
    MembershipOfferErrors,
    LoginForm,
  },
  props: {
    error: {
      type: Object,
      default: () => ({}),
    },
  },
  head() {
    return {
      title: 'Espace Pro',
      meta: [
        {
          name: 'viewport',
          content:
            'width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no',
        },
      ],
    }
  },
  computed: {
    isOffline() {
      return this.$nuxt && this.$nuxt.isOffline
    },
    statusCode() {
      return (this.error && this.error.statusCode) || 500
    },
    isFromUnauthorized() {
      const error = this.error

      if (this.statusCode === 401) {
        this.logoutAndRedirect()
      }

      if (
        error &&
        error.networkError &&
        error.networkError.response &&
        error.networkError.response.status === 401
      ) {
        this.logoutAndRedirect()
      }

      return (
        error &&
        error.networkError &&
        error.networkError.response &&
        error.networkError.response.status === 401
      )
    },
    message() {
      return this.error.message || '<%= messages.client_error %>'
    },
  },
  methods: {
    reload() {
      return window.location.reload()
    },
    authenticated() {
      this.$router.go(0)
    },
    async logoutAndRedirect() {
      await this.$apolloHelpers.onLogout().then(() => {
        window.location.href = process.env.APP_CLIENT_BASE_URL + '/login'
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.title {
  font-size: rem(40px);
}
.desc {
  color: #000;
}
.main-content {
  width: 100%;
  margin-top: rem(100px);
}

.form-content {
  @include make-col-ready();
  @include make-col(12);

  @include media-breakpoint-up(md) {
    @include make-col(6);
  }
}

.form-title {
  margin-bottom: rem(30px);
}
</style>
