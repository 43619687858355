<template>
  <div class="onboarding">
    <OnboardingMessage
      v-if="isFromAppPro()"
      :message="$t('modalAppPro.onboarding')"
      :button-text="$t('modalAppPro.close')"
    />
    <MenuApp v-if="isFromAppPro()" />
    <Navbar v-else />
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-md-8 col-xl-9 order-md-2">
          <nuxt
            class="onboarding__main"
            :class="'onboarding__main--' + routeName"
          />
        </div>
        <div class="col-12 col-md-4 col-xl-3 order-md-1">
          <Menu class="onboarding__menu" />
        </div>
      </div>
    </div>
    <GlobalErrorReporter />
  </div>
</template>

<script>
import Navbar from '@/components/navbar'
import GlobalErrorReporter from '@/components/global-error-reporter'
import Menu from '@/components/onboarding-menu'
import OnboardingMessage from '@/components/app-pro/modal'
import MenuApp from '@/components/app-pro/menu-app.vue'
import { appPro } from '@/mixins/app-pro'

export default {
  components: {
    MenuApp,
    Navbar,
    GlobalErrorReporter,
    Menu,
    OnboardingMessage,
  },
  mixins: [appPro],

  computed: {
    routeName() {
      return this.$route.name
    },
  },
}
</script>
