/* istanbul ignore file */
import { ApolloLink } from 'apollo-link'
import fetch from 'cross-fetch'
import { HttpLink } from 'apollo-link-http'
import appLinks from './app-links'

/**
 * @param {object} ctx - Nuxt Context
 *
 * @returns {ApolloLink}
 */
export default ctx => {
  /**
   * @const {HttpLink}
   */
  const httpLink = new HttpLink({
    uri: process.env.API_ENDPOINT,
    fetch,
  })

  const links = appLinks(ctx)

  links.push(httpLink)

  return ApolloLink.from(links)
}
