import { ApolloLink } from 'apollo-link'
import RefreshTokenObservableBuilder from './RefreshTokenObservableBuilder'

export default ({ apolloClient, apolloHelpers, triggerAppError }) => {
  return new ApolloLink((operation, forward) => {
    const builder = new RefreshTokenObservableBuilder({
      apolloClient: apolloClient(),
      apolloHelpers: apolloHelpers(),
      triggerAppError: triggerAppError(),
    })

    return builder.buildObservable(operation, forward)
  })
}
