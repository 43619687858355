<template>
  <div class="motive-color-select">
    <Multiselect
      v-model="selectValue"
      :show-labels="false"
      :show-pointer="false"
      :searchable="false"
      :preserve-search="true"
      :options="options"
    >
      <template slot="option" slot-scope="option">
        <div class="motive-select__label-group">
          {{ option.value }}
          <AppointmentPurposeIcon :color="option.option.color" />
        </div>
      </template>
      <template slot="singleLabel" slot-scope="option">
        <div class="motive-select__label-group">
          <AppointmentPurposeIcon :color="option.option.color" />
        </div>
      </template>
      <template #noOptions>{{ $t('noOptions') }}</template>
      <template #noResult>{{ $t('noResult') }}</template>
    </Multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import AppointmentPurposeIcon from '@/components/motive/appointment-purpose-icon'
import { COLORS, DEFAULT_COLOR } from '@/constants/purpose-colors'

export default {
  components: {
    Multiselect,
    AppointmentPurposeIcon,
  },
  props: {
    targetedProps: { required: false, type: String, default: undefined },
    value: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      colors: { ...COLORS },
      defaultColor: DEFAULT_COLOR,
      innerValue: this.value,
      selectValue: null,
    }
  },
  computed: {
    options() {
      const colors = this.colors
      delete colors.GRAY

      return Object.values(colors).map(opt => ({
        value: opt,
        color: opt,
      }))
    },
  },
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit('input', newVal)
      this.$emit('change', newVal) // to keep the actual @change Handler
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal
    },
    // Handles multiselect model changes.
    selectValue(newVal) {
      if (newVal && this.targetedProps) {
        this.innerValue = newVal[this.targetedProps]
        if (!this.innerValue) {
          this.innerValue = newVal
        }
      } else {
        this.innerValue = newVal
      }
    },
    // Watch External Options
    options: {
      immediate: true,
      handler(newOptions) {
        if (this.value && this.targetedProps) {
          this.selectValue = newOptions.find(
            o => o[this.targetedProps] === this.value
          )
          if (!this.selectValue) {
            this.selectValue = this.value
          }
        } else {
          this.selectValue = this.value
        }
      },
    },
  },
  created() {
    if (!this.innerValue) {
      this.innerValue = this.defaultColor
      this.selectValue = {
        value: this.defaultColor,
        color: this.defaultColor,
      }
    }
  },
}
</script>

<style lang="scss">
.motive-color-select {
  width: 94px;
  height: 50px;

  .multiselect {
    &__tags {
      height: 50px;
      padding: 13px;
    }
    &__placeholder {
      display: none;
    }
    &__single {
      margin: 4px 0;
      font-size: 18px;
    }
    &__select {
      z-index: 1;
    }
  }
}
</style>
