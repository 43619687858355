const middleware = {}

middleware['authenticated'] = require('../src/middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['dashboard-validation'] = require('../src/middleware/dashboard-validation.js')
middleware['dashboard-validation'] = middleware['dashboard-validation'].default || middleware['dashboard-validation']

middleware['datalayer'] = require('../src/middleware/datalayer.js')
middleware['datalayer'] = middleware['datalayer'].default || middleware['datalayer']

middleware['guest'] = require('../src/middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['logrocket-context'] = require('../src/middleware/logrocket-context.js')
middleware['logrocket-context'] = middleware['logrocket-context'].default || middleware['logrocket-context']

middleware['onboarding-validation'] = require('../src/middleware/onboarding-validation.js')
middleware['onboarding-validation'] = middleware['onboarding-validation'].default || middleware['onboarding-validation']

middleware['sentry-context'] = require('../src/middleware/sentry-context.js')
middleware['sentry-context'] = middleware['sentry-context'].default || middleware['sentry-context']

middleware['workflow-validation'] = require('../src/middleware/workflow-validation.js')
middleware['workflow-validation'] = middleware['workflow-validation'].default || middleware['workflow-validation']

export default middleware
