<template>
  <div v-if="errors.length > 0" class="errors">
    <b>Erreurs</b><br />
    <ul>
      <li v-for="error in errors" :key="error.field">{{ error.message }}</li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    errors: {
      type: Array,
      required: true,
    },
  },
}
</script>
<style lang="scss" scoped>
.errors {
  padding: rem(20px);
  background-color: #f5d4d4;
}
</style>
