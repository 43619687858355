<template>
  <div
    class="tag"
    :data-id="id"
    :class="{
      'tag--white': white,
      'tag--grey': grey,
      'tag--counter': counter,
      'tag--red': red,
      'tag--small': small,
    }"
  >
    <slot></slot>
    <DeleteIcon
      v-if="removable"
      width="13"
      class="tag__remove"
      @click="$emit('remove', id)"
    />
  </div>
</template>
<script>
import DeleteIcon from '@/assets/icons/croix.svg'

export default {
  components: {
    DeleteIcon,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
    white: {
      type: Boolean,
      default: false,
    },
    grey: {
      type: Boolean,
      default: false,
    },
    counter: {
      type: Boolean,
      default: false,
    },
    red: {
      type: Boolean,
      default: false,
    },
    removable: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style lang="scss" scoped>
.tag {
  display: inline-block;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-weight: normal;
  color: $navy;
  letter-spacing: normal;
  background-color: #ecf1f4; // @TODO use variable

  &--white {
    background-color: white;
  }
  &--counter {
    padding: 5px 10px;
    margin: 0;
    font-size: $font-size-sm;
    background-color: #d8dfe3;
    border-radius: 3px;
  }
  &--red {
    color: $red;
    background-color: #fcd4d4;
  }
  &--grey {
    color: white;
    background-color: #a5b0be; // @TODO use variable

    .tag__remove /deep/ path {
      stroke: #fff;
    }
  }
  &--small {
    padding: 7px 10px;
    font-size: $font-size-sm;
  }
  &__remove {
    margin-right: 5px;
    margin-left: 10px;
    cursor: pointer;
  }
}
</style>
