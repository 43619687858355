<template>
  <div class="title" :class="{ 'title--subtitle': subtitle }">
    <component :is="titleTag" class="title__title"><slot></slot></component>
    <div class="title__description"><slot name="description"></slot></div>
  </div>
</template>
<script>
const SUBTITLE_TAG = 'h2'

export default {
  props: {
    tag: {
      type: String,
      default: 'h1',
    },
    subtitle: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    titleTag() {
      return this.subtitle ? SUBTITLE_TAG : this.tag
    },
  },
}
</script>
<style lang="scss" scoped>
.title {
  margin-bottom: rem(30px);

  &__title {
    margin-bottom: 10px;
    font-size: 32px;
  }
  &__description {
    color: darken($gray-light, 20%);

    .title--subtitle & {
      margin-top: 0;
    }
  }
}
</style>
