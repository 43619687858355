<template>
  <div class="onboarding__status">
    <div class="onboarding__status-title">
      {{ $t('OnboardingStatus.title:myAccount') }}
    </div>

    <OnboardingProgress :value="profileCompletionRate" />

    <div class="row">
      <div class="col-12">
        <BFormCheckbox
          v-model="validationInProgress"
          :disabled="!profileCompleted"
          :class="{
            success: validationInProgress,
            'onboarding__status--ready':
              profileCompleted && !validationInProgress,
          }"
          switch
          @change="sendRequest"
        >
          {{
            validationInProgress
              ? $t('OnboardingStatus.profileInPendingValidation')
              : $t('OnboardingStatus.profileIsOffline')
          }}</BFormCheckbox
        >
      </div>
      <div class="offset-md-2 col-10 onboarding__status-comment">
        <p class="comment__text">{{ comment }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue'
import OnboardingProgress from '@/components/onboarding-progress'
import {
  showMyOnboardingProgress,
  createGoOnlineRequest,
} from '@/graphql/user.gql'

export default {
  components: {
    OnboardingProgress,
    BFormCheckbox,
  },
  data: () => ({
    validationInProgress: false,
    profileCompleted: false,
    profileCompletionRate: 0,
  }),
  apollo: {
    me: {
      query: showMyOnboardingProgress,
      fetchPolicy: 'network-only',
      update: () => '', // don't put anything into "me"
      result({ data }) {
        if (!data) {
          return
        }
        const {
          me: {
            Business: {
              SignUp: { profileCompletionRate, CurrentStates },
            },
          },
        } = data

        this.profileCompletionRate = profileCompletionRate
        this.validationInProgress = !!CurrentStates.nodes.find(
          s => s.state === 'VALIDATION_IN_PROGRESS'
        )
        this.profileCompleted = !!CurrentStates.nodes.find(
          s => s.state === 'PROFILE_COMPLETED'
        )
      },
    },
  },
  computed: {
    comment() {
      let wording = this.$t('OnboardingStatus.completeYourProfile')

      if (this.profileCompleted) {
        wording = this.$t('OnboardingStatus.profileCompleted')
        if (this.validationInProgress) {
          wording = this.$t('OnboardingStatus.waitForValidation')
        }
      }

      return wording
    },
  },
  methods: {
    /**
     * sendRequest
     *
     * Triggered on checkbox change
     * it refetch showMyOnboardingProgress
     * can't cancel request
     *
     */
    async sendRequest(checked) {
      if (!checked) {
        this.validationInProgress = true
        return
      }

      await this.$apollo.mutate({
        mutation: createGoOnlineRequest,
        variables: {
          input: {
            CreateGoOnlineRequest: { dummy: true },
          },
        },
        refetchQueries: ['showMyOnboardingProgress'],
      })

      this.$emit('change', checked)
    },
  },
}
</script>

<style lang="scss" scoped>
.onboarding__status {
  padding: rem(20px);

  .onboarding-progress {
    margin-bottom: 25px;
  }

  .onboarding__status--ready {
    color: $danger;
  }

  &-title {
    margin-bottom: rem(13px);
    font-weight: bold;
    font-weight: 600;
    line-height: 1.44;
    text-align: justify;
  }

  &-comment {
    .comment__text {
      font-size: rem(14px);
      font-style: italic;
    }
  }
}
</style>
