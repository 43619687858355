<template>
  <div class="menu">
    <nuxt-link :to="{ name: backlink.route }" class="menu__back-link"
      ><BackIcon /> <slot name="profile-picture"></slot> {{ backlink.name }}
    </nuxt-link>

    <div class="menu__links">
      <span v-for="item in items" :key="item.name">
        <nuxt-link
          v-if="item.route"
          :to="{ name: item.route }"
          class="menu__links__item"
          active-class="menu__links__item--active"
          exact
        >
          {{ item.name }}
        </nuxt-link>
        <a
          v-else-if="item.internalLink"
          target="_self"
          :href="item.internalLink"
          class="menu__links__item"
        >
          {{ item.name }}
        </a>
        <a v-else target="_blank" :href="item.url" class="menu__links__item">
          {{ item.name }}
        </a>
      </span>
    </div>
  </div>
</template>

<script>
import BackIcon from '@/assets/icons/arrow-left.svg'

export default {
  components: {
    BackIcon,
  },
  props: {
    backlink: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.menu {
  padding-top: rem(55px);
  padding-left: rem(29px) !important; // to avoid col-* paddings
  background-color: rgba(169, 182, 188, 0.1);

  &__back-link {
    display: flex;
    align-items: center;
    margin-bottom: rem(40px);
    font-size: $font-size-sm;

    svg {
      margin-right: rem(7px);
    }
  }

  &__links {
    &__item /deep/ {
      display: block;
      padding-top: rem(20px);
      padding-bottom: rem(20px);
      text-decoration: none !important;
    }
    &__item--active /deep/ {
      font-weight: 600;
    }
  }
}
</style>
