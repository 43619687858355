import { setContext } from 'apollo-link-context'
import { ANONYMOUS_TOKEN } from '@/services/auth'

export default operationNames =>
  setContext((operation, { headers }) => {
    if (!operationNames.includes(operation.operationName)) {
      return {}
    }

    return {
      headers: {
        ...headers,
        authorization: `Bearer ${ANONYMOUS_TOKEN}`,
      },
    }
  })
