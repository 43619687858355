import jwtDecode from 'jwt-decode'
import { refreshToken as refreshTokenMutation } from '@/graphql/auth.gql'

/**
 * @const {string}
 */
export const ANONYMOUS_TOKEN = process.env.API_TOKEN

export const TOKEN_TYPE = 'Bearer'

/**
 * Request a new token from server
 *
 * @param {function} done
 * @param {ApolloClient} apolloClient
 * @param {string} oldToken
 *
 * @returns {Promise}
 */
export function refreshToken({ done, apolloClient, oldToken }) {
  return apolloClient
    .mutate({
      mutation: refreshTokenMutation,
      variables: { input: { token: oldToken } },
      fetchPolicy: 'no-cache',
    })
    .then(result => {
      const token = result.data.refreshToken.Token.token

      done(token)

      return token
    })
}

export function getTokenForOperationFromContext(ctx) {
  return getTokenFromContext(ctx) || ANONYMOUS_TOKEN
}

export function isAnonymousToken(token) {
  if (!token) {
    return true
  }

  return token === ANONYMOUS_TOKEN
}

export function userIsLoggedFromContext(ctx) {
  const token = getTokenFromContext(ctx)

  return !isAnonymousToken(token)
}

export function userIsAnonymousFromContext(ctx) {
  const token = getTokenFromContext(ctx)

  return isAnonymousToken(token)
}

export function getUserIdFromContext(ctx) {
  const token = getTokenFromContext(ctx)

  if (token && !isAnonymousToken(token)) {
    try {
      const userId = jwtDecode(token).uid

      if (userId === undefined) {
        return null
      }

      return userId
    } catch (error) {}
  }

  return null
}

function getTokenFromContext(ctx) {
  return ctx.app.$apolloHelpers.getToken()
}
