<template>
  <b-navbar :toggleable="toggleable" type="app">
    <div>
      <b-navbar-brand :to="{ name: 'index' }">
        <Logo class="logo" />
        <LogoSmall class="logo--small" />
      </b-navbar-brand>
    </div>
  </b-navbar>
</template>
<script>
import Vue from 'vue'
import { NavbarPlugin } from 'bootstrap-vue'
import Logo from '@/assets/icons/logo-white.svg'
import LogoSmall from '@/assets/icons/logo-white-small.svg'

Vue.use(NavbarPlugin)

export default {
  components: { Logo, LogoSmall },
  props: {
    toggleable: {
      type: String,
      default: 'lg',
    },
  },
}
</script>
<style lang="scss">
.navbar-app {
  margin-bottom: 20px;
  color: $navbar-dark-color;
  background-color: $navbar-dark-color-bg;

  @include media-breakpoint-down(sm) {
    font-size: rem(16px);
  }

  .navbar-brand {
    margin-right: rem(20px);
    color: $navbar-dark-color;

    @include media-breakpoint-down(sm) {
      padding: 0; // extend boostrap
      margin-right: 0;
    }
    // switch logo when size is < md
    .logo {
      width: auto;
      height: 44.4px;

      @include media-breakpoint-down(md) {
        display: none;
      }

      &--small {
        display: none;
        height: 28px;
        margin-right: 0;
        margin-left: 15px;

        @include media-breakpoint-down(md) {
          display: inline;
        }
      }
    }
  }
}
</style>
