import { setContext } from 'apollo-link-context'

export default logRocket =>
  setContext((operation, { headers }) => {
    const recordingURL = logRocket().sessionURL

    if (recordingURL) {
      return {
        headers: {
          ...headers,
          'X-LogRocket-URL': recordingURL,
        },
      }
    }

    return {}
  })
