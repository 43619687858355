<template>
  <div />
</template>
<script>
import Vue from 'vue'
import { ToastPlugin } from 'bootstrap-vue'
import { hasError, setError } from '@/graphql/app.gql'

Vue.use(ToastPlugin)

export default {
  name: 'GlobalErrorReporter',
  data: () => ({
    error: null,
  }),
  apollo: {
    error: {
      query: hasError,
    },
  },
  watch: {
    error(val) {
      if (val) {
        this.makeToast(val)
        this.clearError()
      }
    },
  },
  methods: {
    clearError() {
      this.$apollo.mutate({
        mutation: setError,
        variables: {
          value: null,
        },
      })
    },
    makeToast(_error) {
      this.$bvToast.toast(this.$t('GlobalErrorReporter.type'), {
        title: this.$t('GlobalErrorReporter.title'),
        autoHideDelay: 5000,
        appendToast: true,
        solid: true,
        variant: 'danger',
      })
    },
  },
}
</script>
