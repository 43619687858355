// Due to transpile problem with babel and jest
// Jest can't use this file
// github.com/logaretm/vee-validate/issues/2310

import Vue from 'vue'
import {
  extend,
  localize,
  setInteractionMode,
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import fr from 'vee-validate/dist/locale/fr.json'
import {
  required,
  email,
  max,
  min,
  // eslint-disable-next-line camelcase
  min_value,
  // eslint-disable-next-line camelcase
  max_value,
} from 'vee-validate/dist/rules'
import localeFr from '@/locales/fr.json'
import {
  validatePhone,
  validateMobilePhone,
  validateSiret,
  validateAdeli,
} from '@/services/util'
import Field from '@/components/field'

extend('required', required)
extend('email', email)
extend('max', max)
extend('max_value', max_value)
extend('min', min)
extend('min_value', min_value)

extend('phone', value => {
  if (!value || validatePhone(value)) {
    return true
  }

  return 'Numéro invalide, 10 chiffres attendus et commençant par 0'
})

extend('siret', value => {
  if (!value || validateSiret(value)) {
    return true
  }

  return 'SIRET invalide, 14 chiffres attendus'
})

extend('adeli', value => {
  if (!value || validateAdeli(value)) {
    return true
  }
  // adeli = 9
  return 'Numéro ADELI invalide, 9 chiffres attendus'
})

extend('mobilePhone', value => {
  if (!value || (typeof value === 'string' && validateMobilePhone(value))) {
    return true
  }

  if (
    !value ||
    (typeof value === 'object' &&
      !value.map(e => validateMobilePhone(e.name)).includes(false))
  ) {
    return true
  }

  return 'Numéro de téléphone invalide, doit être un numéro de téléphone mobile (commençant par 06 ou 07)'
})

extend('emailCustom', {
  ...email,
  message: 'Le champ email doit être une adresse e-mail valide',
})

extend('url', value => {
  const regex = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
    '(\\#[-a-z\\d_]*)?$','i') // validate fragment locator
  ;

  if (value.match(regex)) {
    return true
  }

  return 'Le site internet saisie est invalide'
})

setInteractionMode('eager')

localize('fr', fr)
localize({
  fr: {
    ...localeFr.validations,
  },
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('Field', Field)
