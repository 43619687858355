export const appPro = {
  data: () => {
    return {
      // USER_AGENT_APP_PRO: 'Mozilla/5.0',
      USER_AGENT_APP_PRO: 'MedoucineAppPro',
    }
  },
  methods: {
    isFromAppPro() {
      const userAgent = navigator.userAgent.split(' ')[0]
      return userAgent === this.USER_AGENT_APP_PRO
    },
  },
}
