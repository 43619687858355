<template>
  <div>
    <nuxt />
    <GlobalErrorReporter />
  </div>
</template>

<script>
import GlobalErrorReporter from '@/components/global-error-reporter'

export default {
  components: {
    GlobalErrorReporter,
  },
}
</script>
