function filterAndFormatErrorsForCode(graphQLErrors, code) {
  if (!graphQLErrors) {
    return []
  }

  return graphQLErrors
    .filter(e => {
      return e.extensions && e.message && e.path && e.extensions.code === code
    })
    .map(e => ({
      field: e.path.join('.'),
      message: e.message,
    }))
}

/**
 * Validation failed error
 * local, code=BAD_REQUEST, status=400, handled by component (eg login error)
 *
 * @export
 * @param {object[]} { graphQLErrors }
 * @returns {boolean}
 */
export function validationFailed({ graphQLErrors = [] }) {
  return (
    graphQLErrors &&
    graphQLErrors.some(e => e.extensions && e.extensions.code === 'BAD_REQUEST')
  )
}

/**
 * Get failed validations
 *
 * @param {Array} graphQLErrors
 */
export function getFailedValidations(graphQLErrors) {
  return filterAndFormatErrorsForCode(graphQLErrors, 'BAD_REQUEST')
}

/**
 * Mutation forbidden error
 * local, code=MUTATION_FORBIDDEN, status=403, handled by component (eg createToken error)
 *
 * @export
 * @param {object[]} { graphQLErrors }
 * @returns {boolean}
 */
export function mutationForbidden({ graphQLErrors = [] }) {
  return (
    graphQLErrors &&
    graphQLErrors.some(
      e => e.extensions && e.extensions.code === 'MUTATION_FORBIDDEN'
    )
  )
}

/**
 * Get mutation forbidden errors
 *
 * @param {Array} graphQLErrors
 */
export function getMutationForbiddenErrors(graphQLErrors) {
  return filterAndFormatErrorsForCode(graphQLErrors, 'MUTATION_FORBIDDEN')
}

export default {
  validationFailed,
  mutationForbidden,
}

// function isServerDown() {} // global, httpCode=5xx, toast
// function isNotFoundError() {} // global, httpCode=404, toast
// function isParseError() {} // global, code=BAD_USER_INPUT, toast
// function isResolverError() // global, extension.category=internal, ?
// function isRateError() // global, httpCode=400 status=429, throttle/rate limit, toast
