<template>
  <div class="motive-select">
    <Multiselect
      v-model="selectValue"
      :options="options"
      :show-labels="false"
      :show-pointer="false"
      :searchable="false"
      :preserve-search="true"
      :placeholder="$t('placeholder.motive:select')"
      :disabled="disabled"
    >
      <template slot="option" slot-scope="option">
        <div class="motive-select__label-group">
          <AppointmentPurposeIcon
            :color="option.option.color"
            :title="option.option.text"
            :private="option.option.private"
          />
        </div>
      </template>
      <template slot="singleLabel" slot-scope="option">
        <div class="motive-select__label-group">
          <AppointmentPurposeIcon
            :color="option.option.color"
            :title="option.option.text"
            :private="option.option.private"
          />
        </div>
      </template>
      <template #noOptions>{{ $t('noOptions') }}</template>
      <template #noResult>{{ $t('noResult') }}</template>
    </Multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import AppointmentPurposeIcon from '@/components/motive/appointment-purpose-icon'

export default {
  components: {
    Multiselect,
    AppointmentPurposeIcon,
  },
  props: {
    targetedProps: { required: false, type: String, default: undefined },
    motiveOptions: { required: true, type: Array },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      innerValue: this.value,
      selectValue: null,
    }
  },
  computed: {
    options() {
      return this.motiveOptions.map(opt => ({
        value: opt.value,
        text: opt.text,
        color: opt.color,
        private: opt.private,
      }))
    },
  },
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit('input', newVal)
      this.$emit('change', newVal) // to keep the actual @change Handler
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal
    },
    // Handles multiselect model changes.
    selectValue(newVal) {
      if (newVal && this.targetedProps) {
        this.innerValue = newVal[this.targetedProps]
        if (!this.innerValue) {
          this.innerValue = newVal
        }
      } else {
        this.innerValue = newVal
      }
    },
    // Watch External Options
    options: {
      immediate: true,
      handler(newOptions) {
        if (this.value && this.targetedProps) {
          this.selectValue = newOptions.find(
            o => o[this.targetedProps] === this.value
          )
          if (!this.selectValue) {
            this.selectValue = this.value
          }
        } else {
          this.selectValue = this.value
        }
      },
    },
  },
}
</script>

<style lang="scss">
.motive-select {
  &__icon {
    width: auto;
    height: 18px;
    margin-right: 6px;
  }

  .motive-select {
    &__label-group {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .multiselect {
    &__tags {
      padding: 13px;
    }
    &__placeholder {
      padding: 0;
      margin: 0;
      color: #35495e;
    }
    &__single {
      margin: 4px 0;
      font-size: 18px;
    }
    &__select {
      z-index: 1;
    }
  }
}
</style>
