<template>
  <div>
    <MenuApp v-if="isFromAppPro()" />
    <Navbar v-else />
    <div class="container">
      <nuxt />
    </div>
    <GlobalErrorReporter />
  </div>
</template>

<script>
import Navbar from '@/components/navbar'
import GlobalErrorReporter from '@/components/global-error-reporter'
import MenuApp from '@/components/app-pro/menu-app.vue'
import { appPro } from '@/mixins/app-pro'

export default {
  components: {
    MenuApp,
    Navbar,
    GlobalErrorReporter,
  },
  mixins: [appPro],
}
</script>
<style lang="scss" scoped>
.container {
  @include media-breakpoint-down(md) {
    width: 100%;
    max-width: 100%;
  }
}
</style>
