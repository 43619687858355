<template>
  <div :class="wrapperClass">
    <ValidationProvider
      v-if="innerComponent || component"
      v-slot="{ valid, errors }"
      :vid="vid || $attrs.name"
      :name="fieldName || $attrs.name || label"
      :rules="rules"
      slim
    >
      <BFormCheckbox
        v-if="component === 'BFormCheckbox'"
        v-model="innerValue"
        v-bind="innerComponentAttrs"
        :class="inputClass"
        :state="errors[0] ? false : valid ? null : null"
        v-on="$listeners"
        >{{ label }}</BFormCheckbox
      >

      <BFormGroup
        v-else
        :invalid-feedback="errors[0]"
        :state="errors[0] ? false : valid ? null : null"
        :label-for="$attrs.name"
        v-bind="formGroupAttrs"
      >
        <template slot="label">
          {{ label }}
          <span v-if="showRequiredIndicator" class="field__required">*</span>

          <BFormText v-if="labelDescription" class="nl2br">{{
            labelDescription
          }}</BFormText>
        </template>

        <div v-if="description" slot="description">{{ description }}</div>

        <div v-if="component === 'Datetime'" class="m-datepicker-wrapper">
          <CalendarIcon class="m-datepicker-icon icon" @click="openDatetime" />
          <Datetime
            ref="datepicker"
            v-model="innerValue"
            v-bind="innerComponentAttrs"
            input-class="form-control"
            class="m-datepicker"
            :state="errors[0] ? false : valid ? null : null"
            v-on="$listeners"
          />
        </div>
        <component
          :is="innerComponent"
          v-else
          v-model="innerValue"
          v-bind="innerComponentAttrs"
          :state="errors[0] ? false : valid ? null : null"
          :class="inputClass"
          :rules="rules"
          v-on="$listeners"
        >
          <template #noOptions
            ><slot name="noOptions">{{ $t('noOptions') }}</slot></template
          >
          <template #noResult
            ><slot name="noResult">{{ $t('noResult') }}</slot></template
          >
        </component>
        <slot></slot>
      </BFormGroup>
      <div v-if="saving" class="field__saving">
        <BSpinner small type="grow"></BSpinner>Sauvegarde..
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
/**
 * Bootstrap
 *
 * FormInput
 * FormCheckbox
 * FormCheckboxGroup
 * FormRadio
 * FormRadioGroup
 * FormSelect
 * FormTextarea
 * BFormText
 *
 * Customs
 *
 * HierarchycalSelect
 * WorkEnvironementSelect
 * CustomerSelect
 * MotiveSelect
 * Wysiwig
 * Datetime
 * AllDayCheckbox
 */
import {
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormRadioGroup,
  BSpinner,
  BFormText,
} from 'bootstrap-vue'
import { Datetime } from 'vue-datetime'
import { Settings } from 'luxon'
import extend from 'lodash/extend'
import VueCkeditor from 'vue-ckeditor2'
import Multiselect from 'vue-multiselect'
import AllDayCheckbox from './all-day-checkbox'
import AppFormSelect from './app-form-select'
import WorkEnvironmentSelect from '@/components/work-environment/work-environment-select'
import MotiveSelect from '@/components/motive/motive-select'
import MotiveColorSelect from '@/components/motive/motive-color-select'
import Tagging from '@/components/tagging'
import TimePicker from '@/components/time-picker'
import HierarchycalSelect from '@/components/hierarchycal-select'
import CustomerSelect from '@/components/customer/customer-select'
import CalendarIcon from '@/assets/icons/calendar.svg'

Settings.defaultLocale = 'fr'

const components = {
  CalendarIcon,
  BFormTextarea,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormText,
  Datetime,
  BFormSelect: AppFormSelect,
  BFormRadioGroup,
  HierarchycalSelect,
  VueCkeditor,
  Tagging,
  Multiselect,
  BSpinner,
  TimePicker,
  WorkEnvironmentSelect,
  MotiveSelect,
  MotiveColorSelect,
  CustomerSelect,
  AllDayCheckbox,
}

export default {
  components,
  inheritAttrs: false,
  props: {
    vid: {
      type: String,
      default: null,
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    // must be included in props
    value: {
      type: null,
      default: null,
    },
    component: {
      type: [String, Object],
      default: 'BFormInput',
    },
    fieldName: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    wrapperClass: {
      type: [Object, String, Array],
      default: '',
    },
    inputClass: {
      type: [Object, String, Array],
      default: '',
    },
    formGroupAttrs: {
      type: Object,
      default: () => {},
    },
    componentAttrs: {
      type: Object,
      default: () => {},
    },
    saving: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: '',
    },
    labelDescription: {
      type: String,
      default: '',
    },
    showRequiredIndicator: {
      type: Boolean,
      default: false,
    },
    withoutDefaultOption: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    innerValue: '',
    innerComponent: null,
    // innerComponentAttrs: {},
  }),
  computed: {
    innerComponentAttrs() {
      const attrs = extend(this.$attrs, this.componentAttrs)

      // handle placeholder when Field is BFormSelect
      if (
        !this.withoutDefaultOption &&
        this.component === 'BFormSelect' &&
        typeof attrs.options === 'object' &&
        attrs.options.length > 0
      ) {
        const multiSelect = document.querySelector('div[data-name="cancelReason"] .multiselect__placeholder');
        if (multiSelect) {
          multiSelect.innerHTML = this.$t('choose');
        } else {
          const valueField = attrs.valueField || attrs['value-field'] || 'value'
          const textField = attrs.textField || attrs['text-field'] || 'text'

          const option = {}
          option[valueField] = ''
          option[textField] = attrs.placeholder

          attrs.options = [option].concat(attrs.options) || this.$t('choose');
        }
      }

      return attrs
    },
  },
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit('input', newVal)
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal
    },
  },
  created() {
    if (this.value !== undefined) {
      this.innerValue = this.value
    }

    this.innerComponent =
      typeof this.component === 'string'
        ? components[this.component]
        : this.component

    if (this.component === 'VueCkeditor') {
      this.innerComponentAttrs.config = {
        removePlugins: 'elementspath',
        resize_enabled: false,
        toolbar: [
          [
            'Bold',
            'Italic',
            '-',
            'NumberedList',
            'BulletedList',
            '-',
            'Maximize',
            'Source',
          ],
        ],
        height: 300,
      }
    }
  },
  methods: {
    openDatetime() {
      this.$refs.datepicker.$el.querySelector('.vdatetime-input').focus()
    },
  },
}
</script>
<style lang="scss" scoped>
.field {
  &__saving {
    font-size: $font-size-sm;
    color: $gray-light;
  }
  &__required {
    color: $red;
  }
}
.icon:hover {
  cursor: pointer;
}
</style>
