export default (name, data = {}) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'track_event',
    event_name: name,
    event_method: undefined,
    event_content: undefined,
    event_value: undefined,
    ...data,
  })
}
