import Vue from 'vue'
import { DateTime, Settings, Duration } from 'luxon'
import datetimeFormat from '@/services/datetime-format'

Settings.defaultLocale = 'fr'

export default ({ app = {} }) => {
  app.$filters = Object.assign(app.$filters || {}, {
    date: (value, fmt) => {
      if (!value) return ''

      if (typeof value === 'object') {
        return datetimeFormat(value, fmt)
      }
      return DateTime.fromISO(value).toFormat(fmt)
    },
    toISO: value => {
      if (!value) return ''

      if (typeof value === 'object') {
        return DateTime.fromJSDate(value).toISO()
      }
      return DateTime.fromISO(value).toISO()
    },
    time: (value, fmt) => {
      if (!value) return ''

      if (typeof value === 'object') {
        return datetimeFormat(value, fmt || 'HH:mm')
      }

      return DateTime.fromISO(value, { zone: 'Europe/Paris' }).toFormat(
        fmt || 'HH:mm'
      )
    },
    timesince: (value, slim = false) => {
      if (!value) return ''

      let relative = DateTime.fromISO(value).toRelative()

      if (slim) {
        relative = relative.replace('il y a', '')
      }
      return relative
    },
    monthDuration: value => {
      const parts = Duration.fromISO(value).toObject()

      let string = ''

      if (parts.years) {
        string = parts.years + ' an'
        if (parts.years > 1) {
          string += 's'
        }
      }
      if (parts.months) {
        if (string) {
          string += ' et '
        }
        string += parts.months + ' mois'
      }

      return string
    },
  })

  // add all methods as filters on each vue component
  Object.keys(app.$filters).forEach(key => {
    Vue.filter(key, app.$filters[key])
  })

  Vue.prototype.$filters = app.$filters

  return app
}
