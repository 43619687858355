/* istanbul ignore file */
import unauthenticatedErrorLink from './unauthenticated-error-link'
import useAnonymousTokenForOperations from './use-anonymous-token-for-operations-link'
// import sentryLink from '@/apollo/links/sentry-link'
import setUserIdHeaderLink from '@/apollo/links/set-user-id-header-link'
import setLogRocketUrlHeaderLink from '@/apollo/links/set-logrocket-url-header-link'
import { getUserIdFromContext } from '@/services/auth'

/**
 * @param {object} ctx - Nuxt Context
 *
 * @returns {Array}
 */
export default ctx => {
  /**
   * @returns {ApolloClient}
   */
  const apolloClient = () => ctx.app.apolloProvider.defaultClient

  const apolloHelpers = () => ctx.app.$apolloHelpers

  // const sentry = () => ctx.app.$sentry

  const logRocket = () => ctx.app.$logRocket

  const getUserId = () => getUserIdFromContext(ctx)

  const triggerAppError = () => ctx.error

  return [
    // sentryLink(sentry),
    useAnonymousTokenForOperations(['refreshToken', 'login']),
    unauthenticatedErrorLink({
      apolloClient,
      apolloHelpers,
      triggerAppError,
    }),
    setUserIdHeaderLink({ /* sentry, */ getUserId }),
    setLogRocketUrlHeaderLink(logRocket),
  ]
}
