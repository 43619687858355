import { validate } from '@/services/workflow/validate-step-access'
import { getRedirectPath } from '@/services/workflow/current-step-finder'

// eslint-disable-next-line consistent-return
export default function(context) {
  const { redirect, error, app, route, params } = context
  return validate({
    redirect,
    error,
    apolloClient: app.apolloProvider.defaultClient,
    routeName: route.name,
    getRedirectPath,
    params,
  })
}
