<template>
  <div v-if="showModal" class="m-modalMessage">
    <div class="m-modalMessage__overlay"></div>
    <div class="m-modalMessage__body">
      <p>{{ message }}</p>
      <a href="/logout-react" class="a-btn -corail -full">
        {{ buttonText }}
      </a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    message: {
      type: String,
      default: ' ',
    },
    buttonText: {
      type: String,
      default: 'Fermer',
    },
  },
  data() {
    return {
      showModal: true,
    }
  },
  mounted() {
    document.body.style.overflow = 'hidden'
  },
  beforeUnmount() {
    document.body.style.overflow = null
  },
}
</script>
