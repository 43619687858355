<template>
  <div class="text-center mt-5">
    <SectionTitle>{{
      $t('MembershipOfferErrors.title:' + state, [name])
    }}</SectionTitle>
    <SectionContent>{{
      $t('MembershipOfferErrors.message:' + state)
    }}</SectionContent>
    <nuxt-link
      v-if="state === 'ACCEPTED'"
      class="btn btn-primary mt-5"
      :to="{ name: 'index' }"
      >{{ $t('MembershipOfferErrors.action:ACCEPTED') }}</nuxt-link
    >
  </div>
</template>
<script>
import SectionTitle from '@/components/section-title'
import SectionContent from '@/components/section-content'

export default {
  components: {
    SectionTitle,
    SectionContent,
  },
  props: {
    membershipOffer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    state() {
      return this.membershipOffer.State.state
    },
    name() {
      return this.membershipOffer.UserInfo.firstName
    },
  },
}
</script>
